import {
  Col,
  Row,
  Divider,
  Typography,
  // Button,
  Modal,
  // Input,
  message,
  Tag,
} from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import DetailItem from "../../components/DetailItem";
import {
  ETransactionStatus,
  initialTransactions,
  TransactionProps,
} from "../../types/transaction";
import SectionContent from "../../components/SectionContent";
import { formatDate } from "../../helpers/constant";
import { listOfStatusFailed, listOfStatusSuccess, tagStyle } from ".";

interface IParams {
  transactionId: string;
}

interface ResponseProps extends BaseResponseProps<TransactionProps> {
  payload: Omit<TransactionProps, "updatedAt">;
}

const TransactionDetail: React.FC = () => {
  const { transactionId } = useParams<keyof IParams>() as IParams;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [transaction, setTransaction] =
    React.useState<TransactionProps>(initialTransactions);
  const [selectedTransactionStatus, setSelectedTransactionStatus] =
    React.useState(transaction.transactionStatus);
  // const [isModalWaybillVisible, setIsModalWaybillVisible] =
  //   React.useState<boolean>(false);
  const [isModalUpdateStatusVisible, setIsModalUpdateStatusVisible] =
    React.useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = React.useState<Record<string, any>>({
    transaction: transaction,
    newStatus: ETransactionStatus.created,
  });
  const [adminFee, setAdminFee] = React.useState<any>();

  const getAdminFee = async () => {
    const raw = await httpRequest.get("/app-configs/ADMIN_FEE");
    // console.log(raw);
    setAdminFee(raw.data.payload.value);
  };

  React.useEffect(() => {
    const fetchTransactionDetail = async () => {
      try {
        setIsLoading(true);
        const res = await httpRequest.get<ResponseProps>(
          "/transactions/" + transactionId
        );
        setSelectedTransactionStatus(res.data.payload.transactionStatus);
        setTransaction({
          ...res.data.payload,
        });
        // console.log(res.data.payload);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchTransactionDetail();
    getAdminFee();
  }, [transactionId, selectedTransactionStatus]);

  const { Text } = Typography;

  async function handleTransactionStatusChange() {
    try {
      setIsLoading(true);
      const res = await httpRequest.patch<ResponseProps>(
        "/transactions/" +
          updateStatus.transaction.transactionId +
          "/transaction-status",
        {
          transactionStatus: updateStatus.newStatus,
        }
      );
      if (res) {
        const newRes = await httpRequest.get<ResponseProps>(
          "/transactions/" + transactionId
        );
        setSelectedTransactionStatus(newRes.data.payload.transactionStatus);
        setTransaction(newRes.data.payload);
        message.success("Successfully change transaction status.");
      }
      setIsLoading(false);
      setIsModalUpdateStatusVisible(false);
    } catch (error) {
      console.log("error handleTransactionStatusChange : ", error);
      message.error(
        "Failed change transaction status. Please Contact Administrator."
      );
      setIsLoading(false);
      setIsModalUpdateStatusVisible(false);
    }
  }

  function formatRupiah(angka: string, prefix: string) {
    let number_string = angka.replace(/[^,\d]/g, "").toString();
    let split = number_string.split(",");
    let sisa = split[0].length % 3;
    let rupiah = split[0].substring(0, sisa);
    let ribuan = split[0].substring(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined
      ? rupiah
      : rupiah
      ? prefix + rupiah + ",00"
      : "";
  }

  // function handleWaybillClicked() {
  //   setIsModalWaybillVisible(true);
  // }

  return (
    <React.Fragment>
      {/* <button onClick={() => console.log(transaction)}>show data</button> */}
      <HeaderSection
        icon="back"
        title={transaction.transactionCode}
        // subtitle={transaction.transactionCode}
        key={transaction.transactionCode + " header section"}
        // rightAction={[
        //   <Button
        //     // size="small"
        //     type="primary"
        //     key={"button waybill"}
        //     onClick={() => {
        //       handleWaybillClicked();
        //     }}
        //   >
        //     Input No. Resi
        //   </Button>,
        // ]}
      />
      <div style={{ padding: "0px 20px" }}>
        <SectionContent
          groupTitle="Order Information"
          groupSubtitle="Order information and status."
        >
          <Row justify="start">
            <Col span={4}>
              <Row align="top" style={{}}>
                <DetailItem
                  label="Transaction ID"
                  children={transaction.transactionCode}
                />
              </Row>
            </Col>
            <Col span={4}>
              <Row
                style={
                  {
                    // paddingLeft: "20%",
                  }
                }
              >
                <DetailItem
                  label="Order Date"
                  children={`${formatDate(transaction.createdAt)}`}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Row>
                <DetailItem
                  label="Transaction Status"
                  children={
                    <Tag
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        borderRadius: 5,
                        padding: 4,
                        border: `2px solid ${
                          listOfStatusFailed.includes(transaction.transactionStatus)
                            ? `${tagStyle[1].color}40`
                            : listOfStatusSuccess.includes(
                                transaction.transactionStatus
                              )
                            ? `${tagStyle[0].color}40`
                            : `${tagStyle[2].color}40`
                        }`,
                        color: `${
                          listOfStatusFailed.includes(transaction.transactionStatus)
                            ? tagStyle[1].color
                            : listOfStatusSuccess.includes(
                                transaction.transactionStatus
                              )
                            ? tagStyle[0].color
                            : tagStyle[2].color
                        }`,
                        backgroundColor: `${
                          listOfStatusFailed.includes(transaction.transactionStatus)
                            ? `${tagStyle[1].color}40`
                            : listOfStatusSuccess.includes(
                                transaction.transactionStatus
                              )
                            ? `${tagStyle[0].color}40`
                            : `${tagStyle[2].color}40`
                        }`,
                        marginBottom: "7%",
                      }}
                    >
                      {transaction.transactionStatus === ETransactionStatus.created
                        ? "Waiting Payment"
                        : transaction.transactionStatus}
                    </Tag>
                  }
                />
              </Row>
            </Col>
          </Row>
        </SectionContent>
        <Divider style={{ marginTop: "1Transaction Information%" }} />
        <SectionContent
          groupTitle="Data Customer"
          groupSubtitle="Customer data associated with ongoing orders."
        >
          <Row justify="start">
            <Col span={4}>
              <Row align="top">
                <DetailItem
                  label="Name"
                  children={transaction.metaBuyer.name}
                />
              </Row>
            </Col>
            <Col span={4}>
              <Row>
                <DetailItem
                  label="Email"
                  children={transaction.metaBuyer.email}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Row>
                <DetailItem
                  label="Phone Number"
                  children={transaction.metaBuyer.phoneNumber}
                />
              </Row>
            </Col>
          </Row>
        </SectionContent>
        <Divider style={{ marginTop: "" }} />
        <SectionContent groupTitle="Transaction Summary"
        groupSubtitle="Transaction details of this order.">
          <Row>
            <Col span={24}>
              <DetailItem
                // label="Items List"
                children={
                  <>
                    {transaction?.transactionItems?.map(
                      (item: any, index: number) => {
                        return (
                          <>
                            <Row key={index} style={{ width: "100%" }}>
                              <Col span={8}>
                                ({item.qty}x) {item.metaProduct.productName}
                              </Col>
                              <Col span={8}>
                                {formatRupiah(`${item.totalPrice}`, "Rp. ")}
                              </Col>
                            </Row>
                          </>
                        );
                      }
                    )}
                    <Divider />
                    {/* <Row>
                      <Col span={8}>Subtotal</Col>
                      <Col span={8}>
                        {formatRupiah(`${transaction.totalFinalPrice}`, "Rp. ")}
                      </Col>
                    </Row> */}
                    <Row style={{ marginTop: 20 }}>
                      <Col span={8}>Admin Fee</Col>
                      <Col span={8}>{formatRupiah(`${adminFee}`, "Rp. ")}</Col>
                    </Row>
                    <Divider />
                    <Row style={{ marginTop: 20 }}>
                      <Col span={8}>
                        <Text strong>Total Payment</Text>
                      </Col>
                      <Col span={8}>
                        <Text strong>
                          {true
                            ? formatRupiah(
                                `${transaction.totalFinalPrice}`,
                                "Rp. "
                              )
                            : "-"}
                        </Text>
                      </Col>
                    </Row>
                  </>
                }
                label={""}
              />
            </Col>
          </Row>
        </SectionContent>
        <Modal
          title="Update Transaction Status"
          open={isModalUpdateStatusVisible}
          onOk={() => {
            handleTransactionStatusChange();
          }}
          confirmLoading={isLoading}
          onCancel={() => {
            setIsModalUpdateStatusVisible(false);
          }}
          closable={false}
          centered
          // cancelButtonProps={{ type: "primary" }}
        >
          <p>
            {" "}
            Are you sure want to change{" "}
            <span style={{ fontWeight: "bold" }}>
              {transaction.transactionCode}
            </span>{" "}
            status from{" "}
            <span style={{ fontWeight: "bold" }}>
              {transaction.transactionStatus}
            </span>{" "}
            to{" "}
            <span style={{ fontWeight: "bold" }}>
              {updateStatus.newStatus === ETransactionStatus.created
                ? "Waiting Payment"
                : updateStatus.newStatus}
            </span>{" "}
            ?
          </p>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default TransactionDetail;

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Tooltip,
  Radio,
  Space,
  Upload,
  Button,
  Card,
  Spin,
  Typography,
  notification
} from "antd";
import React, { ChangeEvent, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";
import { generateFormRules } from "../../helpers/formRules";
import {
  UploadChangeParam,
  UploadFile,
  RcFile,
  UploadProps,
} from "antd/lib/upload/interface";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getErrorMessage } from "../../helpers/errorHandler";
import { initialProduct, ProductProps } from "../../types/products.type";
import SectionContent from "../../components/SectionContent";
import { theme } from "../../assets/theme";
import {
  getProductFile,
  processUploadProductFile,
} from "../../api/productFile";
import { GetProductFileURLPayload } from "../../types/productFile.type";
import { set } from "date-fns";
const { Text } = Typography;

interface ILocation {
  productId: string;
}

interface ResponseProps extends BaseResponseProps<ProductProps> {
  payload: Omit<ProductProps, "createdAt" | "updatedAt">;
}

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
  ],
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
];

const type = "DragableUploadList";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface DragableUploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  file: UploadFile;
  fileList: UploadFile[];
  moveRow: (dragIndex: any, hoverIndex: any) => void;
}

const DragableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
}: DragableUploadListItemProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  return (
    <div
      ref={ref}
      className={`ant-upload-list-item  ${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", border: "none" }}
    >
      {file.status === "error" ? errorNode : originNode}
    </div>
  );
};

const ProductEdit: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { productId } = useParams<keyof ILocation>() as ILocation;
  const formRef =
    React.useRef<FormInstance<Omit<ProductProps, "createdAt" | "updatedAt">>>(
      null
    );

  const [showUploader, setShowUploader] = React.useState<boolean>(false);
  const [showUploadLink, setShowUploadLink] = React.useState<boolean>(false);
  const [saveButtonState, setSaveButtonState] = React.useState<boolean>(true);
  const [productIdValid, setProductIdValid] = React.useState<boolean>(false);
  const [linkProduct, setLinkProduct] = React.useState<string>("");
  const [linkError, setLinkError] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<ProductProps>({
    ...initialProduct,
  });
  const [originalPriceString, setOriginalPriceString] = React.useState<any>("");
  const [finalPriceString, setFinalPriceString] = React.useState<any>("");
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [fileProduct, setFileProduct] = React.useState<any>();
  const [productFileURL, setProductFileURL] =
    React.useState<GetProductFileURLPayload>();

  const productFileRef = React.useRef<any>();

  const [images, setImages] = React.useState<
    UploadChangeParam<UploadFile<any>>["fileList"]
  >([]);
  const [willBeDeletedImage, setWillBeDeletedImage] =
    React.useState<UploadFile<any>>();
  const [isLoadingDeleteImage, setIsLoadingDeleteImage] = React.useState(false);

  const handleCancel = () => setPreviewOpen(false);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const fetchProductDetail = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<ResponseProps>(
        "/products/" + productId
      );
      setProduct(res.data.payload);

      if (res.data.payload.productType === "file") {
        if (res.data.payload.productContentURL) {
          fetchProductFile();
        }
        setShowUploader(true);
      }

      if (res.data.payload.productType === "link") {
        if (res.data.payload.productContentURL) {
          setLinkProduct(res.data.payload.productContentURL);
          form.setFieldValue("link", res.data.payload.productContentURL);
          console.log(res.data.payload.productContentURL);
        }
        setShowUploadLink(true);
      }

      if (formRef.current) {
        formRef.current.setFieldsValue({ ...res.data.payload });
      }
    } catch (error) {
      console.log("error get product detail", error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkIsSaveable = () => {
    // if (
    //   (showUploader && !(productFileURL?.productContentURL || fileProduct)) ||  (showUploadLink && !((productFileURL?.productContentURL || linkProduct) && !linkError)) // Disable if showUploadLink is true and productFileURL or linkProduct is falsey, or if linkError is truthy
    // ){
    //   setSaveButtonState(false);
    // }
    if (productIdValid){
      setSaveButtonState(false);
    }
  }

  const fetchProductFile = async () => {
    try {
      setIsLoading(true);

      const res = await getProductFile(productId);
      setProductFileURL(res);
    } catch (error) {
      console.log("error get product detail", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchProductDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createProduct = async (
    props: Omit<ProductProps, "createdAt" | "updatedAt">
  ) => {
    try {
      setIsLoadingAction(true);

      const res = await httpRequest.post<BaseResponseProps<ProductProps>>(
        "/products",
        {
          ...props,
          productContentURL: showUploadLink ? linkProduct : null,
          finalPrice: !product.finalPrice
            ? product.originalPrice
            : product.finalPrice,
          originalPrice: product.originalPrice,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.data.payload) {
        await uploadNewImage(res.data.payload.productId || "");
        message.success("Success create " + res.data.payload.productName);
      }
      if (fileProduct && res.data.payload.productId) {
        await processUploadProductFile(
          res.data.payload.productId,
          fileProduct,
          setIsLoadingAction
        );
      }
      navigate("/product");
    } catch (error: any) {
      console.log("error create product", error);
      setIsLoadingAction(false);
      if (error.data && error.data.message === "Validation error" && error.status === 500) {
        notification.error({
          message: "Error",
          description: "Product with this Product ID already exists. Please choose a different Product ID.",
        });
      }
    } 
  };

  const updateProduct = async (
    props: Omit<ProductProps, "createdAt" | "updatedAt" | "statusLoading">
  ) => {
    try {
      setIsLoadingAction(true);
      console.log("props", product.description);
      if (product.description === "<p><br></p>" || product.description === "<p> </p>"){
        message.error("Description cannot be empty");
        setIsLoadingAction(false);
        return;
      }
      if (productId && product.description!=="<p><br></p>" && product.description!=="<p> </p>") {
        await Promise.all([
          httpRequest.patch("/products/" + productId, {
            ...props,
            productCode: product.productCode,
            productContentURL: showUploadLink ? linkProduct : null,
            finalPrice: !product.finalPrice
              ? product.originalPrice
              : product.finalPrice,
            originalPrice: product.originalPrice,
          }),
        ]);
      }

      if (fileList.length > 0) {
        const formData = new FormData();
        fileList.forEach((image) =>
          formData.append("images", image.originFileObj as RcFile)
        );
        const post = await httpRequest.post(
          `/product-images/upload/${productId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        let list = fileList
          .filter((value: any) => value.hasOwnProperty("productImageId"))
          .map((value: any, idx: number) => {
            return {
              ...value,
              order: idx + 1,
            };
          });

        if (post) {
          const getList = fileList
            // eslint-disable-next-line array-callback-return
            .map((value: any, idx: number) => {
              if (!value.hasOwnProperty("productImageId")) {
                return {
                  ...value,
                  order: idx + 1,
                };
              }
            })
            .filter((value: any) => value !== undefined)
            .map((value: any, idx: number) => {
              return {
                ...value,
                ...post.data.payload.map(({ order, ...rest }: any) => {
                  return rest;
                })[idx],
              };
            });
          console.log(getList);
          list.push(...getList);
        }
        await httpRequest.post(
          `/product-images/adjust-list/${productId}`,
          {
            images: list,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // console.log(formData)
      }

      if (fileProduct) {
        await processUploadProductFile(
          productId,
          fileProduct,
          setIsLoadingAction
        );
      } else if (productFileURL?.productContentURL) {
        try {
          const response = await fetch(productFileURL.productContentURL);
          const fileProduct = await response.blob();

          await processUploadProductFile(
            productId,
            fileProduct,
            setIsLoadingAction
          );
        } catch (error) {
          console.error("Gagal mengunggah file lama ke server:", error);
        }
      }

      message.success("Success update " + props.productName + " data");
      navigate("/product");
    } catch (error: any) {
      console.log("error create product", error);
      
      setIsLoadingAction(false);

      if (error.data && Array.isArray(error.data.message)) {
        const productCodeUniqueViolationError = error.data.message.find(
          (message: any) =>
            message.type === "unique violation" && message.path === "productCode"
        );

        if (productCodeUniqueViolationError) {
          notification.error({
            message: "Error",
            description: "Product with this Product ID already exists. Please choose a different Product ID.",
          });
        }
      }
      
    }
  };

  const uploadNewImage = async (productId: string) => {
    try {
      if (fileList.length > 0) {
        const formData = new FormData();
        fileList.forEach((image) =>
          formData.append("images", image.originFileObj as RcFile)
        );
        await httpRequest.post(
          `/product-images/upload/${productId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    } catch (error) {
      console.log("error upload image product", error);
    }
  };

  const moveRow = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleClickDelete = () => {
    setDeleteModalOpen(true);
  }

  const handleDeleteImage = async () => {
    try {
      setIsLoadingDeleteImage(true);
        const res =await httpRequest.delete("/product-images/" + productId);
        message.success("Success delete image");
      setPreviewImage("");
      setFileList([]);
      setImages(images.filter((item) => item.uid !== willBeDeletedImage?.uid));
      setIsLoadingDeleteImage(false);
      setWillBeDeletedImage(undefined);
      setDeleteModalOpen(false);
    } catch (err) {
      message.error(getErrorMessage(err));
      setIsLoadingDeleteImage(false);
    }
  };

  const handleSubmit = async (
    values: Omit<ProductProps, "createdAt" | "updatedAt" | "statusLoading">
  ) => {
    if (!product.originalPrice) {
      message.error("Error: empty original price");
    } else {
      if (productId) {
        updateProduct(values);
      } else {
        createProduct(values);
      }
    }
  };

  const checkFileTypes = (newFileList: UploadFile) => {
    if (newFileList.type === "image/png" || newFileList.type === "image/jpeg") {
      return true;
    } else {
      return false;
    }
  }

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    console.log("preview-img", previewImage);
    if (checkFileTypes(newFileList[newFileList.length - 1])) {
    setFileList(newFileList);
    } else {
      message.error("File type must be png or jpeg");
    }
  };

  const addCommas = (num: any) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");

  function isValidUrl(input: any) {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    return urlPattern.test(input);
  }

  useEffect(() => {
    if (productId) {
      const fetchProductDetail = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>(
            "/products/" + productId
          );

          if (res.data.payload) {
            setProduct(res.data.payload);
            setOriginalPriceString(addCommas(res.data.payload.originalPrice));
            setFinalPriceString(addCommas(res.data.payload.finalPrice));
            const dataImages = res.data.payload.images?.map((item: any) => ({
              ...item,
              url: item.imageUrl,
              uid: item.productImageId,
              order: item.order,
            }));

            if (dataImages && dataImages.length > 0) {
              setFileList(dataImages);
            }
          }
        } catch (error) {
          console.log("error fetch detail product", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchProductDetail();
    }
    checkIsSaveable();
  }, [productId, location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setLinkError(!isValidUrl(linkProduct));
  });

  const onChangeProductFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    // Upload Body
    // const formData = new FormData();
    // formData.append("file", file);
    setFileProduct(file);

    // Source to show image
    // const src = await new Promise((resolve) => {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = () => resolve(reader.result as string);
    // });

    console.log("testbro", file);
    // console.log(src);
  };
  // const btnSubmitRef = useRef(null);

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={(productId ? "Edit" : "Create") + " Product"}
        // subtitle="Manage your menu data"
        rightAction={
          <Space>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
             disabled={ productIdValid && !showUploader && !(productFileURL?.productContentURL || fileProduct)}            
              loading={isLoadingAction}
              type="primary"
              onClick={(e) => {
                formRef?.current?.submit();
              }}
            >
              Save
            </Button>
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false} style={{ boxShadow: "none" }}>
          <SectionContent
            groupTitle="Product Information"
            groupSubtitle="Information of the current available products."
          >
            <Form
              form={form}
              ref={formRef}
              name="productForm"
              layout="vertical"
              onFinish={product.originalPrice >= product.finalPrice ? handleSubmit : () => message.error("Final price must be lower than original price")}
              initialValues={product}
              autoComplete="off"
            >
              <Form.Item label="Images">
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    key={previewImage}
                    // src={previewImage}
                  />
                </Modal>
                {/* <Button onClick={() => console.log(fileList.length)}>
                  Show File List
                </Button> */}
                <DndProvider backend={HTML5Backend}>
                  <Upload
                    beforeUpload={() => false}
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={handlePreview}
                    onRemove={handleClickDelete}
                    listType="picture-card"
                    itemRender={(originNode, file, currFileList) => (
                      <DragableUploadListItem
                        originNode={originNode}
                        file={file}
                        fileList={currFileList}
                        moveRow={moveRow}
                      />
                    )}
                  >
                    {fileList.length > 0 ? "" : uploadButton}
                  </Upload>
                </DndProvider>
                <Text style={{color:'gray'}}><InfoCircleOutlined/> make sure the dimensions image are 800 x 800 px</Text>
              </Form.Item>
              <Form.Item
                label="Product Name"
                name="productName"
                rules={[
                  ...generateFormRules("Product Name", ["required"]),
                  { max: 255, message: "Nama produk terlalu panjang" },
                ]}
              >
                <Input
                  value={product.productName}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      productName: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Product ID"
                name="productCode"
                rules={generateFormRules("Product Code", ["required"])}
              >
                <Input
                  onChange={(e) =>{
                    const inputValue = e.target.value;

                    // Define a regex pattern to match valid characters
                    const validPattern = /^[a-zA-Z0-9_\-]+$/;

                     // Check if the input matches the valid pattern
                    if (validPattern.test(inputValue)) {
                      // Update the state if the input is valid
                      console.log("valid");

                      setProductIdValid(false);
                    setProduct({
                      ...product,
                      productCode: inputValue,
                    });
                     } else {
                      console.log("invalid", product.productCode);
                      setProductIdValid(true);
                      // Show an error message if the input is invalid
                        message.error("Invalid format. Please use only alphabetical characters, numbers, '_', or '-'.");
                      }
                    }}
                />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={generateFormRules("Description", ["required"])}
              >
                <ReactQuill
                  theme="snow"
                  value={product.description}
                  onChange={(val) =>
                    setProduct({ ...product, description: val })
                  }
                  modules={quillModules}
                  formats={quillFormats}
                />
              </Form.Item>

              <div style={{ display: "flex", gap: 50 }}>
                <Form.Item
                  style={{ flex: 1 }}
                  label="Original Price"
                  rules={generateFormRules("Original Price", ["required"])}
                  required
                >
                  <Input
                    required
                    value={originalPriceString}
                    addonBefore={"Rp"}
                    onChange={(e) => {
                      setOriginalPriceString(
                        addCommas(removeNonNumeric(e.target.value))
                      );
                      setProduct({
                        ...product,
                        originalPrice: Number(removeNonNumeric(e.target.value)),
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ flex: 1 }}
                  label="Final Price"
                  rules={generateFormRules("Final Price", ["required"])}
                >
                  <Input
                    value={finalPriceString}
                    addonBefore={"Rp"}
                    onChange={(e) => {
                      setFinalPriceString(
                        addCommas(removeNonNumeric(e.target.value))
                      );
                      setProduct({
                        ...product,
                        finalPrice: Number(removeNonNumeric(e.target.value)),
                      });
                    }}
                  />
                </Form.Item>
              </div>

              <Form.Item
                label="Status"
                name="isPublished"
                rules={generateFormRules("Status", ["required"])}
              >
                <Radio.Group value={product.isPublished}>
                  <CustomRadio value={true}>Published</CustomRadio>
                  <CustomRadio value={false}>Unpublished</CustomRadio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Product Type"
                name="productType"
                rules={generateFormRules("Product Type", ["required"])}
              >
                <Radio.Group
                  value={product.productType}
                  onChange={(e) => {
                    setShowUploader(e.target.value === "file");
                    setShowUploadLink(e.target.value === "link");
                  }}
                >
                  <CustomRadio value={"file"}>File</CustomRadio>
                  <CustomRadio value={"voucher"}>Voucher</CustomRadio>
                  <CustomRadio value={"link"}>Link</CustomRadio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                rules={
                  showUploader
                    ? generateFormRules("file", ["required"])
                    : generateFormRules("", ["required"])
                }
                label={"File"}
                style={{
                  display: showUploader ? "block" : "none",
                }}
              >
                <input
                  type="file"
                  hidden
                  onChange={onChangeProductFile}
                  ref={productFileRef}
                />
                <div
                  style={{
                    border: `1px solid ${theme.colors.ash400}`,
                    padding: 10,
                    borderRadius: 5,
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", gap: 10 }}>
                    {productFileURL?.productContentURL || fileProduct ? (
                      <div
                        style={{
                          gap: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 5,
                          backgroundColor: theme.colors.ash400,
                        }}
                      >
                        <CheckCircleOutlined
                          style={{
                            fontSize: 20,
                            color: theme.colors.green500,
                          }}
                        />
                        <div>
                          {fileProduct ? "File Selected" : "File Exist "}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          gap: 10,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 5,
                          backgroundColor: theme.colors.ash400,
                        }}
                      >
                        <ExclamationCircleOutlined
                          style={{
                            fontSize: 20,
                            // color: theme.colors.green500,
                          }}
                        />
                        <div>File Empty</div>
                      </div>
                    )}
                    <Button
                      type="primary"
                      onClick={() => {
                        if (productFileURL?.productContentURL) {
                          Modal.confirm({
                            title: "Confirmation",
                            content:
                              "Are you sure want to replace current file with new file?",
                            onOk: () => {
                              if (productFileRef.current) {
                                (productFileRef.current as any).click();
                              }
                            },
                          });
                        } else {
                          if (productFileRef.current) {
                            (productFileRef.current as any).click();
                          }
                        }
                      }}
                    >
                      {productFileURL?.productContentURL || fileProduct
                        ? "Choose New File"
                        : "Choose File"}
                    </Button>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                name={"link"}
                style={{ display: showUploadLink ? "block" : "none" }}
                rules={generateFormRules("Link", ["link"])}
              >
                <Input
                  addonBefore={"Link"}
                  onChange={(e) => {
                    setLinkProduct(e.target.value);
                  }}
                  value={linkProduct}
                />
              </Form.Item>
            </Form>
          </SectionContent>
        </Card>
      </Spin>

      <Modal
        open={deleteModalOpen}
        title="Confirmation"
        visible={!!willBeDeletedImage}
        onOk={handleDeleteImage}
        onCancel={() => {
          setDeleteModalOpen(false);
          setWillBeDeletedImage(undefined);
        }}
        okText="Yes"
        confirmLoading={isLoadingDeleteImage}
        okButtonProps={{ type: "primary" }}
      >
        <p>Are you sure want to delete this image?</p>
      </Modal>
    </React.Fragment>
  );
};

// const CustomSpace = styled(Space)`
//   width: 100%;
//   .ant-space-item {
//     width: 100%;
//   }
// `;

// const CustomUpload = styled(Upload)`
//   width: 100%;
//   .ant-upload {
//     width: 100%;
//   }
// `;

export default ProductEdit;

export const CustomRadio = styled(Radio)`
  margin-right: 5rem;
  .ant-radio-checked .ant-radio-inner {
    border-color: red;
    box-shadow: none;
  }
  .ant-radio:hover .ant-radio-inner {
    background-color: white;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: red;
  }
`;

import { Card } from "antd";
import styled from "styled-components";

type ReportCardPropsType = {
  title: string | JSX.Element;
  extra?: JSX.Element;
  children?: string | JSX.Element;
  extraStyle?: React.CSSProperties;
};

const ReportCard = ({title, extra, children, extraStyle} : ReportCardPropsType) => {
  return (
    <Card
      className="report-card"
      style={
        {
          padding: "16px 16px", 
          boxShadow: 'none', 
          borderRadius:8, 
          borderColor:"#E8ECF3", 
          marginBottom: 0, 
          height: "100%",
          ...extraStyle
        }
      }
    >
      <Header>
        <div>
          {title}
        </div>
        <div>
          {extra}
        </div>
      </Header>
      <Content>
        {children}
      </Content>
    </Card>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

`

const Content = styled.div`
  
`
export default ReportCard;
import {
  DownOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Dropdown, Image, Menu, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../assets/theme";
import useFetchList from "../hooks/useFetchList";
import { PaymentConfigProps } from "../types/paymentConfig.type";
import { useQuery } from "../screens/transaction";

const { Text } = Typography;

const SwitchPaymentId: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const paymentAccountId = query.get("paymentAccountId");
  const { data } = useFetchList<PaymentConfigProps>({
    endpoint: "payment-accounts",
  });
  const [label, setLabel] = React.useState<string>();
  const labelRef = React.useRef<any[]>([]);

  const setInitLabel = () => {
    const rawLabel = data.filter(
      (account) => account.paymentAccountId === paymentAccountId
    );
    setLabel(rawLabel[0]?.label);
  };

  React.useEffect(() => {
    setInitLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAccountId, data]);

  const handleMenuClick = (e: any, label: string) => {
    setLabel(label);
    navigate(`?paymentAccountId=${e.key}`);
  };

  const ContentDropdown = (
    <Menu style={{ marginTop: 15 }}>
      <Menu.ItemGroup>
        {/* <Menu.Item
          key={"All Accounts"}
          icon={<UsergroupAddOutlined style={{ fontSize: 18 }} />}
          onClick={(e) => {
            navigate("/transactions");
            setLabel("All Accounts");
          }}
        >
          <Text strong style={{ color: theme.colors.primary }}>
            All Accounts
          </Text>
        </Menu.Item> */}
        {data &&
          data.map((account: PaymentConfigProps, index: number) => {
            return (
              <Menu.Item
                key={account.paymentAccountId}
                icon={<UserOutlined />}
                onClick={(e) => handleMenuClick(e, account.label)}
              >
                <div ref={(ref) => (labelRef.current[index] = ref)}>
                  {account.label}
                </div>
              </Menu.Item>
            );
          })}
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown overlay={ContentDropdown}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 10 }}>
          <Image src="/images/PaymentAccount.svg" style={{ marginRight: 10 }} />
        </div>
        <span
          style={{ marginRight: 10, display: "flex", flexDirection: "column" }}
        >
          <Text style={{ color: theme.colors.charcoal200 }}>
            Switch Payment ID
          </Text>
          <Text strong>{label ?? "All Accounts"}</Text>
        </span>
        <Icon />
      </div>
    </Dropdown>
  );
};

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`;

export default SwitchPaymentId;

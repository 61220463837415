import React, { useMemo } from "react";
import { Menu } from "antd";
import {
  iconBook as IconBook,
  // iconChartLine as IconChartLine,
  iconUserCircle as IconUserCircle,
  iconCreditCard as IconCreditCard,
  iconListUnordered as IconListUnordered,
  iconMoreGridBig as IconMoreGridBig,
  iconUsers as IconUsers,
  iconSettingsFuture as IconSettingsFuture,
  iconFileDocument as IconFileDocument,
  iconFileRemove as IconFileRemove,
  iconUsersGroup as IconUsersGroup,
  iconArrowReload as IconReloadArrow
} from "../assets/icons/Sidebar";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
// import { EUserRole } from "../types/user.type";
import useAuth from "../hooks/useAuth";
import { theme } from "../assets/theme";
import useConfigApp from "../hooks/useConfigApp";
import { LineChartOutlined } from "@ant-design/icons";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useAuth();
  const { sidebarCollapsed } = useConfigApp();

  // const isHavePermission = (finds: EUserRole[]) => {
  //   return finds.includes(role);
  // };

  const isAdmin = useMemo(() => role.includes("admin"), [role]);

  const filterPermission = (items: any[]) => {
    return items
      .map((item) => {
        if (item?.permission === undefined || item.permission === true) {
          let newObject = item;
          delete item.permission;
          if (newObject.children) {
            newObject.children = filterPermission(newObject.children);
          }
          return newObject;
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const divider = "-------";

  const MENUS = [
    {
      permission: isAdmin,
      type: "group",
      // label: `${sidebarCollapsed ? '---------' : 'REPORT & TRANSACTION'}`,
      label: `${sidebarCollapsed ? divider : "TRANSACTION"}`,
      children: [
        {
          key: "/report",
          label: "Report",
          icon: <LineChartOutlined />,
        },
        {
          key: "/transactions",
          label: "Transaction",
          icon: <IconListUnordered />,
        },
      ],
    },
    {
      permission: isAdmin,
      type: "group",
      label: `${sidebarCollapsed ? divider : "MASTER & CONFIG"}`,
      children: [
        {
          key: "/product",
          label: "Product",
          icon: <IconMoreGridBig />,
        },
        {
          key: "/payment-config",
          label: "Payment Config",
          icon: <IconCreditCard />,
        },
        {
          key: "/web-config",
          label: "Web Config",
          icon: <IconSettingsFuture/>,
        },
        ,
        {
          key: "/privacy-policy",
          label: "Privacy Policy",
          icon: <IconFileDocument />,
        },
        ,
        {
          key: "/terms-conditions",
          label: "Term & Conditions",
          icon: <IconFileRemove/>,
        },
        {
          key: "/faqs",
          label: "FAQs",
          icon: <IconBook />,
        },
        {
          key: "/about-us",
          label: "About Us",
          icon: <IconUsersGroup />,
        },
        {
          key: "/about-refund",
          label: "About Refund",
          icon: <IconReloadArrow />,
        },
      ],
    },
    {
      permission: isAdmin,
      type: "group",
      label: `${sidebarCollapsed ? divider : "USERS"}`,
      children: [
        {
          key: "/admin",
          label: "Admin",
          icon: <IconUserCircle />,
        },
        {
          key: "/customer",
          label: "Customer",
          icon: <IconUsers />,
        },
      ],
    },
  ];

  const items = filterPermission(MENUS) as MenuProps["items"];

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    // console.log(res);
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && "children" in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : "");
            if (submenu && "children" in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  "path" in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  return (
    <>
      {/* <button onClick={() => console.log(sidebarCollapsed)}>show</button> */}
      <Menu
        style={{
          backgroundColor: theme.colors.primary,
        }}
        mode="inline"
        defaultSelectedKeys={[convertPathName()]}
        defaultOpenKeys={getSelectedParent()}
        items={items}
        onClick={({ key }) => {
          navigate(key);
        }}
      />
    </>
  );
};
export default Sidebar;

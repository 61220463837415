import React from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Radio,
  Space,
  Spin,
  message,
  Typography,
  Row,
  Col,
} from "antd";
import HeaderSection from "../../components/HeaderSection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SectionContent from "../../components/SectionContent";
import {
  PaymentConfigProps,
  initialPaymentConfig,
} from "../../types/paymentConfig.type";
import { generateFormRules } from "../../helpers/formRules";
import { CustomRadio } from "../product/Edit";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import { priceFormat } from "../../helpers/priceFormat";

const { Text, Title } = Typography;

const PaymentConfigsEdit = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { paymentConfigId } = useParams<any>();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [totalTransactions, setTotalTransactions] = React.useState<string>();

  React.useEffect(() => {
    const locationLength = location.pathname.split("/").length;
    const edit = location.pathname.split("/")[locationLength - 1] as string;
    // console.log(edit);
    if (edit.toUpperCase() === "EDIT") {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [paymentConfigId, location]);

  const formRef = React.useRef<any>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [paymentConfig, setPaymentConfig] =
    React.useState<PaymentConfigProps>(initialPaymentConfig);

  const createPaymentAccount = async (e: PaymentConfigProps) => {
    try {
      setIsLoadingAction(true);
      const raw = await httpRequest.post<BaseResponseProps<PaymentConfigProps>>(
        "/payment-accounts",
        e
      );
      console.log(raw.status);
      message.success("Payment Account Added");
      navigate("/payment-config");
    } catch (err: any) {
      console.log(err);
      message.error(err.data.message);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const updatePaymentAccount = async (e: PaymentConfigProps) => {
    try {
      setIsLoadingAction(true);
      const raw = await httpRequest.patch<
        BaseResponseProps<PaymentConfigProps>
      >(`/payment-accounts/${paymentConfigId}`, e);
      console.log(raw.status);
      message.success("Payment Account Updated");
      navigate("/payment-config");
    } catch (err: any) {
      console.log(err);
      message.error(err.data.message);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (e: PaymentConfigProps) => {
    if (paymentConfigId) {
      updatePaymentAccount(e);
    } else {
      createPaymentAccount(e);
    }
    // console.log(formRef.current.getFieldsValue);
  };

  React.useEffect(() => {
    if (paymentConfigId) {
      const fetchPaymentAccountDetail = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<
            BaseResponseProps<PaymentConfigProps>
          >("/payment-accounts/" + paymentConfigId);

          if (res.data.payload) {
            const updateData = {
              ...res.data.payload,
              maxTotalTrxAmount: res.data.payload.maxTotalTrxAmount,
          };
            form.setFieldsValue(updateData);
            }
        } catch (error) {
          console.log("error fetch detail payment account ", error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchPaymentAccountDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentConfigId, location]);

  const getTotalTransactions = async () => {
    const raw = await httpRequest.get<any>(
      `/transactions/report/total-transaction-by-payment-accounts?paymentAccountIds=${paymentConfigId}`
    );
    console.log("ini total data", raw.data.payload)
    // console.log(raw.data.payload.results);
    setTotalTransactions(raw.data?.payload?.results[0]?.countTransaction);
  };

  React.useEffect(() => {
    getTotalTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <>
      <HeaderSection
        icon="back"
        title={
          (!paymentConfigId ? "Create" : isEdit ? "Edit" : "Detail") +
          " Payment Account"
        }
        // subtitle="Manage your menu data"
        rightAction={
          <Space>
            {!paymentConfigId ? (
              <>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  // size="small"
                  loading={isLoadingAction}
                  type="primary"
                  onClick={() => formRef?.current?.submit()}
                >
                  Create
                </Button>
              </>
            ) : isEdit ? (
              <>
                <Button
                  // size="small"
                  type="default"
                  onClick={() => navigate(-1)}
                  style={{ backgroundColor: "transparent" }}
                >
                  Cancel
                </Button>
                <Button
                  // size="small"
                  loading={isLoadingAction}
                  type="primary"
                  onClick={() => formRef?.current?.submit()}
                >
                  Save Edit
                </Button>
              </>
            ) : (
              <>
                <Button
                  // size="small"
                  loading={isLoadingAction}
                  type="primary"
                  onClick={() =>
                    navigate(`/payment-config/${paymentConfigId}/edit`)
                  }
                >
                  Edit
                </Button>
              </>
            )}
          </Space>
        }
      />
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <SectionContent
            groupTitle="Payment Account"
            groupSubtitle="Payment account information to be saved."
          >
            <Form
              disabled={!paymentConfigId ? false : isEdit ? false : true}
              form={form}
              ref={formRef}
              name="productForm"
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={initialPaymentConfig}
              autoComplete="off"
            >
              <Form.Item
                label="Label"
                name="label"
                rules={generateFormRules("Label", ["required"])}
              >
                <Input
                  value={paymentConfig.label}
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      label: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Client ID"
                name="clientId"
                rules={generateFormRules("Client ID", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      clientId: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Client Key"
                name="clientKey"
                rules={generateFormRules("Client Key", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      clientKey: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Secret Key"
                name="secretKey"
                rules={generateFormRules("Secret Key", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      secretKey: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Frontend Callback URL"
                name="frontendCallbackURL"
                rules={generateFormRules("Frontend Callback URL", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      frontendCallbackURL: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Backend Callback URL"
                name="backendCallbackURL"
                rules={generateFormRules("Backend Callback URL", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      backendCallbackURL: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Backend Webhook URL"
                name="backendWebhookURL"
                rules={generateFormRules("Backend Webhook URL", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      backendWebhookURL: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Base URL"
                name="baseURL"
                rules={generateFormRules("Base URL", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      baseURL: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Base URL Payment Page"
                name="baseURLPaymentPage"
                rules={generateFormRules("Base URL Payment Page", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      baseURLPaymentPage: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Limit Transaction"
                name="maxTotalTrxAmount"
                rules={generateFormRules("Limit Transaction", ["required"])}
              >
                <Input
                  onChange={(e) =>
                    setPaymentConfig({
                      ...paymentConfig,
                      maxTotalTrxAmount: parseInt(e.target.value),
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="Status"
                name="isActive"
                rules={generateFormRules("Status", ["required"])}
              >
                <Radio.Group value={paymentConfig.isActive}>
                  <CustomRadio value={true}>Active</CustomRadio>
                  <CustomRadio value={false}>Deactive</CustomRadio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </SectionContent>
          {!isEdit ? (
            <SectionContent
              groupTitle="Data Transaction"
              groupSubtitle="Transaction data information."
            >
              <Row style={{ gap: 200 }}>
                <Col>
                  <Title level={5}>Total Transaction</Title>
                  <Text>{totalTransactions ?? "0"}</Text>
                </Col>
                <Col>
                  <Title level={5}>Data Transactions</Title>
                  <Text
                    className="table-link"
                    style={{ color: "blue" }}
                    onClick={() => navigate(`transactions-list`)}
                  >
                    See Transaction
                  </Text>
                </Col>
              </Row>
            </SectionContent>
          ) : (
            <></>
          )}
        </Card>
      </Spin>
    </>
  );
};

export default PaymentConfigsEdit;

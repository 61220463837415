import { BaseResponsePaginationProps, BaseResponseProps } from "./config.type";
// import { ProductImagesProps } from "./productImages.type";

export interface PaymentConfigProps {
  paymentAccountId: string;
  vendor: string;
  label: string;
  stage: string;
  clientId: string;
  clientKey: string;
  secretKey: string;
  frontendCallbackURL: string;
  backendCallbackURL: string;
  backendWebhookURL: string;
  baseURL: string;
  baseURLPaymentPage: string;
  maxTotalTrxAmount: number;
  totalAmountTransaction: number | null;
  isActive: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface FetchPaymentConfigResponse
  extends BaseResponseProps<PaymentConfigProps[]> {
  code: string;
  message: string;
  payload: PaymentConfigProps[];
}

export interface FetchAllProductResponse
  extends BaseResponsePaginationProps<PaymentConfigProps> {
  code: string;
  message: string;
  payload: {
    count: number;
    prev: string;
    next: string;
    results: PaymentConfigProps[];
  };
}

export interface FetchAllPaymentAccountReportResponse
  extends BaseResponsePaginationProps<ReportPaymentAccountProps> {
  code: string;
  message: string;
  payload: {
    count: number;
    prev: string;
    next: string;
    results: ReportPaymentAccountProps[];
  };
}

export interface ReportPaymentAccountProps {
  paymentAccountId: string;
  label: string | null;
  sumTotalFinalPrice: number;
  countTransaction: string
}

export const initialPaymentConfig: PaymentConfigProps = {
  paymentAccountId: "",
  vendor: "",
  label: "",
  stage: "",
  clientId: "",
  clientKey: "",
  secretKey: "",
  frontendCallbackURL: "",
  backendCallbackURL: "",
  backendWebhookURL: "",
  baseURL: "",
  baseURLPaymentPage: "",
  maxTotalTrxAmount: 0,
  totalAmountTransaction: 0,
  isActive: false,
  createdAt: "",
  updatedAt: "",
};

export interface AppConfigProps {
  configId?: string;
  key?: string;
  value: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export const initAppConfig: AppConfigProps = {
  configId: "",
  key: "",
  value: "",
  createdAt: "",
  updatedAt: "",
};

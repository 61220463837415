import { DatePicker, Typography } from "antd";
import ReportCard from "../../components/ReportCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { useEffect, useState } from "react";
import { httpRequest } from "../../helpers/api";
import { useQuery } from "../../screens/transaction";
;


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const { Text } = Typography;

const TransactionStatistic = () => {
  const query = useQuery();

  const paymentAccountId = query.get("paymentAccountId");
  const [yearFilter, setYearFilter] = useState<string>(moment().year().toString())
  const [transactionData, setTransactionData] = useState<number[]>([0,0,0,0,0,0,0,0,0,0,0,0])

  useEffect(() => {
    const fetchTransactionStatistic = async () => {
      try {
        let data = [0,0,0,0,0,0,0,0,0,0,0,0];
        const response = await httpRequest.get<any>(`/transactions/report/total-transaction-one-year-by-payment-account?paymentAccountId=${paymentAccountId}&year=${yearFilter}`);
        console.log("report", response)
        response.data.payload.results.forEach((item: any) => {
          data[item.month - 1] = data[item.month -1] + parseInt(item.sumTotalFinalPrice);
        })
        setTransactionData(data)
        console.log(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchTransactionStatistic();
  }, [paymentAccountId, yearFilter])

  const onSelectYearFilter = (value: string) => {
    setYearFilter(value)
  }

  const disabledYear: RangePickerProps['disabledDate'] = (current) => {
    // can not select year before this year
    return current && current > moment().startOf('year');
  }
  const formatNumber = (num: number) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(2) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(2) + 'K';
    } else {
      return num.toString();
    }
  }
  const maxData = Math.max(...transactionData);
  const numberOfPoints = 6;
  const interval = Math.ceil(maxData / numberOfPoints);
  const arrayYAxis = Array.from({ length: numberOfPoints + 1 }, (_, index) => index * interval);


  const data = {
    labels,
    datasets: [
      {
        data: transactionData,
        backgroundColor: '#FD7014',
      },
    ],
  };
  return (
    <ReportCard
      title={
        <div>
          <div>
            <Text strong style={{fontSize: 14}}>Transaction Statistic</Text>
          </div>
        </div>
      }
      extra={<div>
        <DatePicker
          picker="year"
          size="small"
          style={{ width: 100, fontSize: 10 }}
          disabledDate={disabledYear}
          dropdownClassName="year-picker"
          onChange={(date, dateString) => onSelectYearFilter(dateString)}
          format={"YYYY"}
          defaultValue={moment().startOf('year')}
        />
      </div>}
    >
      <Bar options={options} data={data} style={{paddingTop: 12}}/>
    </ReportCard>
  )
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      }
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default TransactionStatistic;
// import { LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  // Divider,
  Form,
  FormInstance,
  Input,
  message,
  Radio,
  // Select,
  Space,
} from "antd";
import React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import HeaderSection from "../../components/HeaderSection";
import { initialFAQ, FAQProps } from "../../types/faq.type";
// import AppLayout from "../layout/AppLayout";
import { httpRequest } from "../../helpers/api";
// import { BaseResponseProps } from "../../types/config.type";
import SectionContent from "../../components/SectionContent";
import TextArea from "antd/lib/input/TextArea";

// interface IParams {
//   faqId: string;
// }

// interface ILocation {
//   faqId: string;
// }

interface ResponseProps {
  payload: Omit<FAQProps, "createdAt" | "updatedAt">;
}

const FAQEdit: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { faqId } = useParams();
  const formRef =
    React.useRef<FormInstance<Omit<FAQProps, "createdAt" | "updatedAt">>>(null);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [faq, setFAQ] = React.useState<FAQProps>(initialFAQ);

  const createFAQ = async (
    props: Omit<FAQProps, "createdAt" | "updatedAt" | "adminId">
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.post("/faqs", props);
      message.success("Created successfully");

      navigate("/faqs");
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const updateFAQ = async (
    props: Omit<FAQProps, "createdAt" | "updatedAt">
  ) => {
    try {
      setIsLoadingAction(true);

      await httpRequest.patch("/faqs/" + faqId, props);
      message.success("Updated successfully");
      navigate("/faqs");
    } catch (error) {
      setIsLoadingAction(false);
    }
  };

  const handleSubmit = async (
    values: Omit<FAQProps, "createdAt" | "updatedAt">
  ) => {
    if (faqId) {
      updateFAQ(values);
    } else {
      createFAQ(values);
    }
  };

  React.useEffect(() => {
    if (faqId) {
      const fetchFAQ = async () => {
        try {
          setIsLoading(true);

          const res = await httpRequest.get<ResponseProps>("/faqs/" + faqId);
          setFAQ(res.data.payload);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };

      fetchFAQ();
    }
  }, [faqId, location]);

  return (
    <>
      <HeaderSection
        icon="back"
        title={(faqId ? "Edit" : "Add") + " FAQ"}
        subtitle="Manage your faq data"
        rightAction={
          <Space>
            <Button onClick={() => navigate("/faqs")}>Cancel</Button>
            <Button
              loading={isLoadingAction}
              type="primary"
              onClick={() => formRef?.current?.submit()}
            >
              Save
            </Button>
          </Space>
        }
      />
      <Card loading={isLoading} bordered={false} style={{ boxShadow: "none" }}>
        <Form
          ref={formRef}
          name="faqForm"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={faqId ? faq : initialFAQ}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="FAQs Data"
            groupSubtitle="Data for creating FAQs."
          >
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: "The question is required.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Active"
              name="isImportant"
              rules={[
                {
                  required: true,
                  message: "The important is required.",
                },
              ]}
            >
              <Radio.Group value={faq.isImportant || false}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="Answer"
              name="answer"
              rules={[
                {
                  required: true,
                  message: "The answer is required.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </SectionContent>
        </Form>
      </Card>
    </>
  );
};

export default FAQEdit;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AntDesignOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Select,
  Typography,
  Button,
  Image,
} from "antd";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import styled from "styled-components";
import useFetchList from "../../hooks/useFetchList";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { initialProduct, ProductProps } from "../../types/products.type";
import { priceFormat } from "../../helpers/priceFormat";
import { theme } from "../../assets/theme";

interface ResponseProps extends BaseResponseProps<ProductProps> {}

const { Text } = Typography;
const { Option } = Select;

const Products = () => {
  const navigate = useNavigate();

  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [isModalUpdateStatusVisible, setIsModalUpdateStatusVisible] =
    React.useState<boolean>(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] =
    React.useState<boolean>(false);

  const [tmpData, setTmpData] = React.useState<ProductProps>(initialProduct);

  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    fetchList,
  } = useFetchList<ProductProps>({
    endpoint: "products",
    limit: 10,
  });

  const handleDelete = async () => {
    try {
      setIsLoadingAction(true);
      const res = await httpRequest.delete<
        BaseResponseProps<{ isSuccess: boolean }>
      >("products/" + tmpData.productId);

      if (res.data.payload.isSuccess) {
        message.success("Success delete " + tmpData.productName);
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsLoadingAction(false);
      setIsModalDeleteVisible(false);
      setTmpData(initialProduct);
      fetchList();
    }
  };

  const handleStatusChange = async () => {
    try {
      setIsLoadingAction(true);
      let newData = [];

      const res = await httpRequest.patch<ResponseProps>(
        "products/" + tmpData.productId,
        {
          isPublished: !tmpData.isPublished,
        }
      );

      newData = data.map((item) => {
        if (item.productId === res.data.payload.productId) {
          return {
            ...item,
            isPublished: res.data.payload.isPublished,
          };
        }
        return item;
      });
      setData(newData);

      message.success("Success change " + tmpData.productName + " status.");
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsLoadingAction(false);
      setIsModalUpdateStatusVisible(false);
      setTmpData(initialProduct);
    }
  };

  const handleChangeStatus = (status: string) => {
    if (status !== "all") {
      setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isPublished: "" }));
    }
  };

  const handleCreateProduct = () => {
    navigate("/product/add");
  };

  const handleClickDetail = (e: ProductProps) => {
    navigate(`/product/${e.productId}`);
  };

  const handleClickAction = (props: ProductProps, key: string) => {
    if (key === "edit") {
      navigate(`/product/${props.productId}/edit`);
    } else if (key === "delete") {
      setIsModalDeleteVisible(true);
      setTmpData(props);
    }
  };

  const columns = [
    {
      title: "IMAGE",
      dataIndex: "",
      key: "",
      render: (record: ProductProps) => {
        return (
          <Image
            width={60}
            height={60}
            style={{
              objectFit: "contain",
              border: `solid 1px ${theme.colors.ash800}`,
              borderRadius: 5,
            }}
            src={
              record.images && record.images.length !== 0
                ? record.images[0]?.imageUrl
                : "/images/no-preview.jpg"
            }
            placeholder={
              <Image
                style={{
                  objectFit: "contain",
                  border: "solid 1px grey",
                }}
                preview={false}
                src="/images/no-preview.jpg"
                width={60}
                height={60}
              />
            }
          />
        );
      },
    },
    {
      title: "PRODUCT ID",
      dataIndex: "productCode",
      key: "productCode",
      sorter: (a: any, b: any) => {
        return `${a.productCode}`.localeCompare(`${b.productCode}`);
      },
      render: (productCode: string, record: ProductProps) => {
        return (
          <>
            {productCode ? (
              <div
                className="table-link"
                onClick={() => handleClickDetail(record)}
              >
                {productCode}
              </div>
            ) : (
              <Text italic>Not Set</Text>
            )}
          </>
        );
      },
    },
    {
      title: "PRODUCT NAME",
      dataIndex: "productName",
      key: "productName",
      sorter: (a: any, b: any) => {
        return `${a.productName}`.localeCompare(`${b.productName}`);
      },
      render: (productName: string) => {
        return (
          <>
            {productName ? (
              <Text>{productName}</Text>
            ) : (
              <Text italic>Not Set</Text>
            )}
          </>
        );
      },
    },
    {
      title: "PRICE",
      key: "",
      dataIndex: "",
      sorter: (a: any, b: any) => {
        // console.log(a);
        return a.finalPrice - b.finalPrice;
      },
      render: (record: ProductProps) => {
        return (
          <>
            {record.originalPrice === record.finalPrice ? (
              <Text strong>{priceFormat(record.originalPrice)}</Text>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ textDecoration: "line-Through" }}>
                  {priceFormat(record.originalPrice)}
                </Text>
                <Text strong>{priceFormat(record.finalPrice)}</Text>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "STATUS",
      key: "isPublished",
      dataIndex: "isPublished",
      render: (isPublished: any, record: ProductProps) => {
        return (
          <Switch
            checked={isPublished}
            onChange={() => {
              setIsModalUpdateStatusVisible(true);
              setTmpData(record);
            }}
          />
        );
      },
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateA - dateB;
      },
      render: (createdAt: any) => <div>{formatDate(createdAt)}</div>,
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: ProductProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: ProductProps) => (
    <Menu onClick={(e) => handleClickAction(record, e.key)}>
      <Menu.Item key="edit">Edit Product</Menu.Item>
      <Menu.Item key="delete">Delete Product</Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <HeaderSection
        icon={<AntDesignOutlined />}
        title="Products"
        rightAction={
          <Space>
            <Button
              type="primary"
              // size="small"
              onClick={handleCreateProduct}
              style={{ width: 139 }}
            >
              Add Product
            </Button>
          </Space>
        }
      />
      <div style={{ padding: "0px 20px" }}>
        {/* <button onClick={() => console.log(data)}>check Data</button> */}
        <ContainerFilter>
          <Input
            style={{ width: "100%" }}
            placeholder="Search by Product Name or Product ID"
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
          <Select
            allowClear
            style={{ width: 250 }}
            onChange={handleChangeStatus}
            placeholder="Status"
          >
            <Option value="all">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </ContainerFilter>

        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />

        <Pagination
          current={pagination.page}
          total={pagination.totalData}
          defaultPageSize={pagination.perPage}
          pageSize={pagination.perPage}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onShowSizeChange={(_current, size) => {
            changeLimit(size);
          }}
          showSizeChanger={true}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={changePage}
        />
      </div>

      <Modal
        title="Update Status Confirmation"
        open={isModalUpdateStatusVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalUpdateStatusVisible(false);
          setTmpData(initialProduct);
        }}
        okText="Yes"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: "primary" }}
        // cancelButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.productName}"</b> status to{" "}
          <b>"{!tmpData.isPublished ? "Active" : "Inactive"}"</b>?
        </p>
      </Modal>

      <Modal
        title="Delete Confirmation"
        open={isModalDeleteVisible}
        onOk={handleDelete}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setTmpData(initialProduct);
        }}
        okText="Yes"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: "primary" }}
        // cancelButtonProps={{ type: "primary" }}
      >
        <p>
          Are you sure want to delete <b>"{tmpData.productName}"</b>?
        </p>
      </Modal>
    </React.Fragment>
  );
};

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  background-color: ${theme.colors.white};
`;

export default Products;

import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import useAuth from '../hooks/useAuth';
import { Spin } from 'antd';
import ProductEdit from '../screens/product/Edit';
import ProductDetail from '../screens/product/Detail';
import Products from '../screens/product';
import Transaction from '../screens/transaction';
import TransactionDetail from '../screens/transaction/detail';
import Reports from '../screens/report';
import ReportDetails from '../screens/report/detail';
import WebConfigs from '../screens/webConfig';
import FAQ from '../screens/faq';
import FAQEdit from '../screens/faq/Edit';
import PaymentConfigsEdit from '../screens/paymentConfig/Edit';
import TransactionsUser from '../screens/user/Transactions';
import TransactionsPaymentConfig from '../screens/paymentConfig/Transactions';
import PaymentConfigs from '../screens/paymentConfig';
import PrivacyPolicy from '../screens/privacyPolicy';
import TermsConditions from '../screens/termsConditions';
import AboutUs from '../screens/aboutUs';
import AboutRefund from '../screens/aboutRefund';

const NotFound = React.lazy(() => import('../NotFound'));

const Login = React.lazy(() => import('../screens/auth/Login'));
const ForgotPassword = React.lazy(() => import('../screens/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../screens/auth/ResetPassword'));

const Profile = React.lazy(() => import('../screens/profile'));
const EditProfile = React.lazy(() => import('../screens/profile/Edit'));
const ChangeMyPassword = React.lazy(() => import('../screens/profile/ChangeMyPassword'));
const ChangePasswordUser = React.lazy(() => import('../screens/user/ChangePassword'));
const User = React.lazy(() => import('../screens/user'));
const UserEdit = React.lazy(() => import('../screens/user/Edit'));
const UserDetail = React.lazy(() => import('../screens/user/Detail'));

const RootNavigator: React.FC = () => {
	const { isLoggedIn } = useAuth();

	return (
		<Suspense fallback={<Spin spinning={true} />}>
			<BrowserRouter>
				<Routes>
					<Route
						path='/'
						element={<Navigate to={isLoggedIn ? '/report' : '/login'} />}
					/>
					<Route element={<PublicRoute />}>
						<Route
							path='/login'
							element={<Login />}
						/>
						<Route
							path='/forgot-password'
							element={<ForgotPassword />}
						/>
						<Route
							path='/reset-password/:sessionId'
							element={<ResetPassword />}
						/>
					</Route>
					<Route element={<PrivateRoute />}>
						<Route
							path='*'
							element={<NotFound />}
						/>
						<Route
							path='/report'
							element={<Reports />}
						/>
						<Route
							path='/report/detail'
							element={<ReportDetails />}
						/>
						<Route
							path='/profile/edit'
							element={<EditProfile />}
						/>
						<Route
							path='/profile'
							element={<Profile />}
						/>
						<Route
							path='/change-password'
							element={<ChangeMyPassword />}
						/>

						<Route
							path='/transactions'
							element={<Transaction />}
						/>
						<Route
							path='/transactions/:transactionId'
							element={<TransactionDetail />}
						/>

						<Route
							path='/product'
							element={<Products />}
						/>
						<Route
							path='/product/add'
							element={<ProductEdit />}
						/>
						<Route
							path='/product/:productId/edit'
							element={<ProductEdit />}
						/>
						<Route
							path='/product/:productId'
							element={<ProductDetail />}
						/>

						<Route
							path='/payment-config'
							element={<PaymentConfigs />}
						/>
						<Route
							path='/payment-config/add'
							element={<PaymentConfigsEdit />}
						/>
						<Route
							path='/payment-config/:paymentConfigId/Edit'
							element={<PaymentConfigsEdit />}
						/>
						<Route
							path='/payment-config/:paymentConfigId'
							element={<PaymentConfigsEdit />}
						/>
						<Route
							path='/payment-config/:paymentConfigId/transactions-list'
							element={<TransactionsPaymentConfig />}
						/>

						<Route
							path='/web-config'
							element={<WebConfigs />}
						/>

						<Route
							path='/privacy-policy'
							element={<PrivacyPolicy />}
						/>

						<Route
							path='/terms-conditions'
							element={<TermsConditions />}
						/>

						<Route
							path='/faqs'
							element={<FAQ />}
						/>
						<Route
							path='/faqs/add'
							element={<FAQEdit />}
						/>
						<Route
							path='/faqs/:faqId/edit'
							element={<FAQEdit />}
						/>

						<Route
							path='/about-us'
							element={<AboutUs />}
						/>

						<Route
							path='/about-refund'
							element={<AboutRefund />}
						/>

						<Route
							path='/admin'
							element={<User userType='admin' />}
						/>
						<Route
							path='/admin/add'
							element={<UserEdit userType='admin' />}
						/>
						<Route
							path='/admin/:userId/edit'
							element={<UserEdit userType='admin' />}
						/>
						<Route
							path='/admin/:userId'
							element={<UserDetail userType='admin' />}
						/>
						<Route
							path='/admin/:userId/change-password'
							element={<ChangePasswordUser />}
						/>

						<Route
							path='/customer'
							element={<User userType='customer' />}
						/>
						<Route
							path='/customer/add'
							element={<UserEdit userType='customer' />}
						/>
						<Route
							path='/customer/:userId/edit'
							element={<UserEdit userType='customer' />}
						/>
						<Route
							path='/customer/:userId'
							element={<UserDetail userType='customer' />}
						/>
						<Route
							path='/customer/:userId/change-password'
							element={<ChangePasswordUser />}
						/>
						<Route
							path='/customer/:buyerEmail/transactions-list'
							element={<TransactionsUser />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</Suspense>
	);
};
export default RootNavigator;

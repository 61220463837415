import { ArrowLeftOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Row, Typography, DatePicker, Tag, Table, Input, Dropdown, Menu, Pagination, Select } from "antd";
import React, { useState } from "react";
import { CustomRangePicker, listOfStatusFailed, listOfStatusSuccess, tagStyle, useQuery } from "../transaction";
import { httpRequest } from "../../helpers/api";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import { useNavigate } from "react-router-dom";
import { FetchPaymentConfigResponse, PaymentConfigProps } from "../../types/paymentConfig.type";
import useFetchList from "../../hooks/useFetchList";
import HeaderSection from "../../components/HeaderSection";
import { TransactionProps, ETransactionStatus } from "../../types/transaction";
import menu from "antd/lib/menu";
import { ProductProps } from "../../types/products.type";
import moment from "moment";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ReportDetails = () => {
  const queryParams = useQuery();
  const navigate = useNavigate();
  const paymentAccountId = queryParams.get("paymentAccountId");

  const {
    isLoading,
    data,
    pagination,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
  } = useFetchList<TransactionProps>({
    endpoint: "transactions",
    limit: 5,
    initialQuery: { 
      paymentAccountId: paymentAccountId,
      transactionStatus: 'paid',
    },
  });

  const getAllPaymentAccountId = async () => {
    const raw = await httpRequest.get<any>("/payment-accounts");
    console.log(raw.data.payload.results)
    const primaryAccount = raw.data.payload.results.find((account : PaymentConfigProps) => account.isActive === true).paymentAccountId
    navigate(`?paymentAccountId=${primaryAccount}`);
  };
  console.log(data);
  const navigateBack = () => {
    navigate(`/report`);
  };
  React.useEffect(() => {
    setQuery((oldVal) => ({ ...oldVal, paymentAccountId }));
  }, [paymentAccountId, setQuery]);

  React.useEffect(() => {
    !paymentAccountId && getAllPaymentAccountId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAccountId]);

  const handleClickDetail = (e: TransactionProps) => {
    navigate(`/transactions/${e.transactionId}`);
  };

  const handleDateRangeFilterChange = (e: any) => {
    if (e !== null) {
      // console.log(e);
      const fromDate = moment(e[0]).toISOString();
      const toDate = moment(e[1]).toISOString();
      setQuery((oldVal) => ({
        ...oldVal,
        filterStartAt: fromDate,
        filterEndAt: toDate,
      }));
    } else {
      setQuery((oldVal) => ({
        ...oldVal,
        filterStartAt: null,
        filterEndAt: null,
      }));
    }
  };

  function formatRupiah(angka: string, prefix: string) {
    let number_string = angka.replace(/[^,\d]/g, "").toString();
    let split = number_string.split(",");
    let sisa = split[0].length % 3;
    let rupiah = split[0].substring(0, sisa);
    let ribuan = split[0].substring(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined
      ? rupiah
      : rupiah
      ? prefix + rupiah + ",00"
      : "";
  }

  const columns = [
    {
      title: "ORDER DATE",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (price: number, record: TransactionProps) => {
        return <div>{formatDate(record.createdAt)}</div>;
      },
    },
    {
      title: "ORDER ID",
      key: "transactionCode",
      dataIndex: "transactionCode",
      render: (text: string, record: TransactionProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "PRODUCT",
      key: "items",
      dataIndex: ["transactionItems", 0, "metaProduct", "productName"],
      render: (text: string, record: TransactionProps, index: number) => {
        const itemsLength = data[index]?.transactionItems?.length;
        return (
          <div>
            <div>{text}</div>
            <div>
              {itemsLength && itemsLength > 1
                ? `+${itemsLength - 1} More`
                : null}
            </div>
          </div>
        );
      },
    },
    {
      title: "CUSTOMER",
      key: "transactionCode",
      dataIndex: ["metaBuyer", "name"],
      render: (text: string, record: TransactionProps, index: number) => {
        // console.log(index);
        return (
          <div>
            <Text strong>{text}</Text>
            <div>{record.metaBuyer.email}</div>
            {/* <div>{data[index]?.metaBuyer?.email}</div> */}
          </div>
        );
      },
    },
    {
      title: "TOTAL",
      key: "totalFinalPrice",
      dataIndex: "totalFinalPrice",
      render: (price: number, record: TransactionProps) => {
        return (
          <div>{formatRupiah(record.totalFinalPrice.toString(), "Rp. ")}</div>
        );
      },
    },
    {
      title: "STATUS",
      key: "transactionStatus",
      dataIndex: "transactionStatus",
      render: (text: string, record: TransactionProps) => {
        return (
          <Tag
            style={{
              fontWeight: "bold",
              borderRadius: 999,
              border: `2px solid ${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? `${tagStyle[1].color}40`
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? `${tagStyle[0].color}40`
                  : `${tagStyle[2].color}40`
              }`,
              color: `${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? tagStyle[1].color
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? tagStyle[0].color
                  : tagStyle[2].color
              }`,
              backgroundColor: `${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? `${tagStyle[1].color}40`
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? `${tagStyle[0].color}40`
                  : `${tagStyle[2].color}40`
              }`,
              // marginBottom: "7%",
            }}
          >
            {record.transactionStatus === ETransactionStatus.created
              ? "Waiting Payment"
              : record.transactionStatus}
          </Tag>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: TransactionProps) => (
        <Dropdown overlay={() => menu()} placement="bottomRight">
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const menu = () => (
    <Menu>
      <Menu.Item key="edit">Detail Transaction</Menu.Item>
    </Menu>
  );
  
  // console.log(data.reduce((acc, cur) => acc + cur.totalFinalPrice, 0))
  return (
    <React.Fragment>
      <HeaderSection
        icon={<ArrowLeftOutlined onClick={navigateBack} style={{fontSize: 16}}/>}
        title="Detail List Transaction"
      />
      <Row gutter={[16, 16]} style={{margin:24}}>
        <Col span={8} >
          <Text type="secondary" style={{fontSize:12, verticalAlign: 'middle'}}>Search </Text><br/>
          <Input
            style={{ width: "100%" }}
            placeholder="Search by Product Name or Product ID"
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col> 
        <Col span={6}>
          <Text type="secondary" style={{fontSize:12, verticalAlign: 'middle'}}>Order Date </Text><br/>
          <RangePicker 
              placeholder={['DD MMM YYYY', 'DD MMM YYYY']}
              suffixIcon={null}
              onChange={handleDateRangeFilterChange}
            />
        </Col> 
        <Col span={3} style={{margin: 'auto 0'}} >
          <br/>
          <Text type="secondary" style={{fontSize:12, verticalAlign: 'middle'}}>Total Orders  </Text>
          <Text strong style={{fontSize:20, verticalAlign: 'middle'}}>{pagination.totalData}</Text>
        </Col> 
        <Col span={7} style={{margin: 'auto 0'}} >
          <br/>
          <Text type="secondary" style={{fontSize:12, verticalAlign: 'middle'}}>Total Transactions  </Text>
          <Text strong style={{fontSize:20, verticalAlign: 'middle'}}>{formatRupiah(data.reduce((acc, cur) => acc + cur.totalFinalPrice, 0).toString(), "Rp. ")}</Text>
        </Col> 
      </Row>
      <Row gutter={[16, 16]} style={{margin:24}}>
        <Col span={24}>
          <Table
            className="latest-transaction-table"
            size="small"
            style={{marginTop: 16}}
            loading={isLoading}
            columns={columns}
            dataSource={data}
            pagination={false}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
          />
        </Col>
        <Col span={12}>
          <Text type="secondary" style={{fontSize:12, verticalAlign: 'middle'}}>
            Per Row
            <Select
              defaultValue={5}
              size="small"
              style={{ width: 60, marginLeft: 8, marginRight: 8 }}
              options={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 20, label: '20' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
              ]}
              onChange={(value:number) => { changeLimit(value) }}
            />
          </Text>
          <Text type="secondary" style={{fontSize:12, verticalAlign: 'middle'}}>Showing {(pagination.page - 1) * (pagination.perPage)+1} to {pagination.page * pagination.perPage} of {pagination.totalData} entries</Text>
        </Col>
        <Col span={12}>
          <Pagination
            style={{float: 'right', verticalAlign: 'middle'}}
            current={pagination.page}
            total={pagination.totalData}
            defaultPageSize={pagination.perPage}
            pageSize={pagination.perPage}
            size="small"
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={changePage}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};



export default ReportDetails;

import { Select, Table, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {useEffect} from "react";
import ReportCard from "../ReportCard";
import { formatDate } from "../../helpers/constant";
import useFetchList from "../../hooks/useFetchList";
import { ETransactionStatus, TransactionProps } from "../../types/transaction";
import { listOfStatusFailed, tagStyle, listOfStatusSuccess } from "../../screens/transaction";

const { Text } = Typography;

const LatestTransaction = () => {
  const navigate = useNavigate();
  const date = new Date();
  const dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
  console.log("dateStart1: ", dateStart);
  const dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const {
    isLoading,
    data,
    setData,
    setQuery
    // setIsLoading,
  }= useFetchList<TransactionProps>({
    endpoint: "transactions",
    limit: 5,
    initialQuery: {
      filterStartAt: dateStart,
      filterEndAt: dateEnd
    }
  });
  console.log("data1st: ", data);
  // data?.filter((item) => {item.createdAt >= dateStart && item.createdAt <= dateEnd});
  

  const handleClickDetail = (e: TransactionProps) => {
    navigate(`/transactions/${e.transactionId}`);
  };

  function formatRupiah(angka: string, prefix: string) {
    let number_string = angka.replace(/[^,\d]/g, "").toString();
    let split = number_string.split(",");
    let sisa = split[0].length % 3;
    let rupiah = split[0].substring(0, sisa);
    let ribuan = split[0].substring(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined
      ? rupiah
      : rupiah
      ? prefix + rupiah + ",00"
      : "";
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const columns = [
    {
      title: "ORDER DATE",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (price: number, record: TransactionProps) => {
        return <div>{formatDate(record.createdAt)}</div>;
      },
    },
    {
      title: "ORDER ID",
      key: "transactionCode",
      dataIndex: "transactionCode",
      render: (text: string, record: TransactionProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "PRODUCT",
      key: "items",
      dataIndex: ["transactionItems", 0, "metaProduct", "productName"],
      render: (text: string, record: TransactionProps, index: number) => {
        const itemsLength = data[index]?.transactionItems?.length;
        return (
          <div>
            <div>{text}</div>
            <div>
              {itemsLength && itemsLength > 1
                ? `+${itemsLength - 1} More`
                : null}
            </div>
          </div>
        );
      },
    },
    {
      title: "CUSTOMER",
      key: "transactionCode",
      dataIndex: ["metaBuyer", "name"],
      render: (text: string, record: TransactionProps, index: number) => {
        // console.log(index);
        return (
          <div>
            <Text strong>{text}</Text>
            <div>{data[index]?.metaBuyer?.email}</div>
          </div>
        );
      },
    },
    {
      title: "TOTAL",
      key: "totalFinalPrice",
      dataIndex: "totalFinalPrice",
      render: (price: number, record: TransactionProps) => {
        return (
          <div>{formatRupiah(record.totalFinalPrice.toString(), "Rp. ")}</div>
        );
      },
    },
    {
      title: "STATUS",
      key: "transactionStatus",
      dataIndex: "transactionStatus",
      render: (text: string, record: TransactionProps) => {
        return (
          <Tag
            style={{
              fontWeight: "bold",
              borderRadius: 999,
              border: `2px solid ${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? `${tagStyle[1].color}40`
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? `${tagStyle[0].color}40`
                  : `${tagStyle[2].color}40`
              }`,
              color: `${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? tagStyle[1].color
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? tagStyle[0].color
                  : tagStyle[2].color
              }`,
              backgroundColor: `${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? `${tagStyle[1].color}40`
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? `${tagStyle[0].color}40`
                  : `${tagStyle[2].color}40`
              }`,
              // marginBottom: "7%",
            }}
          >
            {record.transactionStatus === ETransactionStatus.created
              ? "Waiting Payment"
              : record.transactionStatus}
          </Tag>
        );
      },
    },
    
  ];

  return (
    <ReportCard
      title={
        <div>
          <div>
            <Text strong style={{fontSize: 14}}>Latest Transaction on {monthNames[date.getMonth()]} {date.getFullYear()}</Text>
          </div>
          <div style={{display:"flex", verticalAlign: "text-top"}}>
            <Text type="secondary" style={{fontSize: 10}}>Data transaksi terkini bulan ini</Text>
          </div>
        </div>
      }
    >
      <>
        <Table
          className="latest-transaction-table"
          size="small"
          style={{marginTop: 16}}
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </>
    </ReportCard>
  )
}

export default LatestTransaction;
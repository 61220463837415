import { BaseResponsePaginationProps } from "./config.type";
import { ProductImagesProps } from "./productImages.type";

export interface ProductProps {
  productId?: string;
  productName: string;
  productCode: string;
  productContentURL?: string;
  productType?: string;
  description: string;
  originalPrice: number;
  finalPrice: number;
  isPublished: boolean;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  images?: ProductImagesProps[];
}

export interface FetchAllProductResponse
  extends BaseResponsePaginationProps<ProductProps> {
  code: string;
  message: string;
  payload: {
    count: number;
    prev: string;
    next: string;
    results: ProductProps[];
  };
}

export const initialProduct: ProductProps = {
  productId: "",
  productName: "",
  productCode: "",
  description: "",
  originalPrice: 0,
  finalPrice: 0,
  isPublished: true,

  updatedAt: "",
  createdAt: "",
};

import {
  Row,
  Space,
  Col,
  Image,
  Button,
  Card,
  Spin,
  Typography,
  // message,
  // Modal,
} from "antd";
import React, { ChangeEvent, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderSection from "../../components/HeaderSection";
import { httpRequest } from "../../helpers/api";
import HTMLPreview from "../../components/HTMLPreview";
import { BaseResponseProps } from "../../types/config.type";
import DetailItem from "../../components/DetailItem";
import { ProductProps } from "../../types/products.type";
import SectionContent from "../../components/SectionContent";
import { theme } from "../../assets/theme";
import styled from "styled-components";
import { priceFormat } from "../../helpers/priceFormat";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  // deleteProductFile,
  getProductFile,
  processUploadProductFile,
} from "../../api/productFile";
import { GetProductFileURLPayload } from "../../types/productFile.type";

interface ResponseProps extends BaseResponseProps<ProductProps> {
  payload: Omit<ProductProps, "createdAt" | "updatedAt">;
}

interface ILocation {
  productId: string;
}

const { Text } = Typography;

const ProductDetail: React.FC = () => {
  const navigate = useNavigate();
  const { productId } = useParams<keyof ILocation>() as ILocation;

  const uploadRef = useRef<any>();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [product, setProduct] = React.useState<ProductProps>();
  const [productFileURL, setProductFileURL] =
    React.useState<GetProductFileURLPayload>();

  const fetchProductDetail = async () => {
    try {
      setIsLoading(true);

      const res = await httpRequest.get<ResponseProps>(
        "/products/" + productId
      );

      if (res.data.payload.productContentURL) {
        fetchProductFile();
      }
      setProduct(res.data.payload);
    } catch (error) {
      console.log("error get product detail", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductFile = async () => {
    try {
      setIsLoading(true);

      const res = await getProductFile(productId);
      setProductFileURL(res);
    } catch (error) {
      console.log("error get product detail", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const confirmationDeleteProductFile = () => {
  //   Modal.confirm({
  //     title: "Confirmation",
  //     content: "Are you sure want to delete this file / content?",
  //     onOk: () => {
  //       handleDeleteProductFile();
  //     },
  //   });
  // };

  // const handleDeleteProductFile = async () => {
  //   try {
  //     setIsLoading(true);

  //     await deleteProductFile(productId);
  //     message.success("File has been removed successfully");

  //     fetchProductFile();
  //   } catch (error) {
  //     console.log("error get product detail", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  React.useEffect(() => {
    fetchProductDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const uploadHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    await processUploadProductFile(productId, file, setIsLoading);
    fetchProductDetail();
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={product?.productCode || ""}
        rightAction={
          <Space>
            <Button
              type="primary"
              // size="small"
              onClick={() => navigate("/product/" + productId + "/edit")}
            >
              Edit
            </Button>
          </Space>
        }
      />

      <Spin spinning={isLoading}>
        <Card bordered={false} style={{ boxShadow: "none" }}>
          <SectionContent
            groupTitle="Data Product"
            groupSubtitle="Data of the current available products."
          >
            {product && product.images && product.images.length !== 0 && (
              <div style={{ marginTop: -25 }}>
                <DetailItem
                  label="Images"
                  children={
                    <Row gutter={[24, 0]}>
                      {product.images.map((item, index) => {
                        return (
                          <Col span={3} key={index}>
                            <Image
                              preview={true}
                              width={100}
                              height={100}
                              src={item.imageUrl || "/images/select-image.jpg"}
                              fallback={"/images/no-preview.jpg"}
                              style={{
                                objectFit: "contain",
                                border: `solid 1px ${theme.colors.ash400}`,
                                borderRadius: 5,
                              }}
                              placeholder={
                                <Image
                                  preview={false}
                                  src="/images/no-preview.jpg"
                                  width={100}
                                  height={100}
                                  style={{ objectFit: "contain" }}
                                />
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  }
                />
              </div>
            )}

            <GridContainer>
              <DetailItem
                label="Product ID"
                value={product?.productCode || ""}
              />

              <DetailItem
                label="Product Name"
                value={product?.productName || ""}
              />

              <DetailItem
                label="Normal Price"
                value={`${priceFormat(product?.originalPrice)}` || ""}
              />

              <DetailItem
                label="Final Price"
                value={`${priceFormat(product?.finalPrice)}` || ""}
              />
            </GridContainer>

            <DetailItem
              label="Description"
              children={
                product && product.description ? (
                  <HTMLPreview html={product.description} />
                ) : (
                  <Text italic>Not Set</Text>
                )
              }
            />

            <div style={{ marginTop: 20 }}>
              <DetailItem
                label="Status"
                value={
                  product && product.isPublished ? "Published" : "Unpublished"
                }
              />
            </div>

            <div style={{ marginTop: 20 }}>
              <DetailItem
                label="Product Type"
                value={`${
                  product?.productType?.charAt(0)?.toUpperCase() ?? ""
                }${product?.productType?.slice(1) ?? "-"}`}
              />
            </div>

            <div
              style={{
                marginTop: 20,
                display: product?.productType === "link" ? "block" : "none",
              }}
            >
              <DetailItem label="Link" value={product?.productContentURL} />
            </div>

            <input
              type="file"
              hidden
              onChange={uploadHandler}
              ref={uploadRef}
            />
            <div
              style={{
                marginTop: 20,
                display: product?.productType === "file" ? "block" : "none",
              }}
            >
              <DetailItem
                label="File"
                children={
                  <div
                    style={{
                      border: `1px solid ${theme.colors.ash400}`,
                      padding: 10,
                      borderRadius: 5,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: 10 }}>
                      {productFileURL?.productContentURL ? (
                        <div
                          style={{
                            gap: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                            backgroundColor: theme.colors.ash400,
                          }}
                        >
                          <CheckCircleOutlined
                            style={{
                              fontSize: 20,
                              color: theme.colors.green500,
                            }}
                          />
                          <div>File Exist </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            gap: 10,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                            backgroundColor: theme.colors.ash400,
                          }}
                        >
                          <ExclamationCircleOutlined
                            style={{
                              fontSize: 20,
                              // color: theme.colors.green500,
                            }}
                          />
                          <div>File Empty</div>
                        </div>
                      )}
                      {/* <Button
                        type="primary"
                        onClick={() => {
                          if (productFileURL?.productContentURL) {
                            Modal.confirm({
                              title: "Confirmation",
                              content:
                                "Are you sure want to replace current file with new file?",
                              onOk: () => {
                                if (uploadRef.current) {
                                  (uploadRef.current as any).click();
                                }
                              },
                            });
                          } else {
                            if (uploadRef.current) {
                              (uploadRef.current as any).click();
                            }
                          }
                        }}
                      >
                        {productFileURL?.productContentURL
                          ? "Choose New File"
                          : "Choose File"}
                      </Button> */}
                    </div>
                    {productFileURL?.productContentURL ? (
                      <div style={{ display: "flex", gap: 10 }}>
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: theme.colors.blue,
                            border: 0,
                          }}
                          onClick={() => {
                            window.open(productFileURL.productContentURL);
                          }}
                        >
                          Download
                        </Button>
                        {/* <Button
                          danger
                          type="primary"
                          onClick={confirmationDeleteProductFile}
                        >
                          Remove
                        </Button> */}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                }
              />
            </div>
          </SectionContent>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

const GridContainer = styled.div`
  display: grid;
  gap: 0;
  padding: 0;
  grid-template-columns: minmax(200px, 320px) minmax(200px, 320px);
  grid-template-rows: 1fr 1fr;
`;

export default ProductDetail;

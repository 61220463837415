import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AntDesignOutlined,
  EditOutlined
} from '@ant-design/icons';
import {
  Modal,
  message,
  Input,
  Select,
  Typography,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import styled from 'styled-components';
import useFetchList from '../../hooks/useFetchList';
import {
  AppConfigProps,
  initAppConfig,
  PaymentConfigProps,
} from '../../types/paymentConfig.type';
import { priceFormat } from '../../helpers/priceFormat';
import { theme } from '../../assets/theme';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface ResponseProps extends BaseResponseProps<PaymentConfigProps> {}

const { Text, Title } = Typography;
const { Option } = Select;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
];

const truncateString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
}

export const WebConfigItem: React.FunctionComponent<{
  title: string;
  value: string;
  handler: () => void;
  showPreview?: boolean;
}> = ({ title, value, handler, showPreview = true }) => {
  return (
    <>
      <WebConfigItemContainer>
        <Text style={{ color: theme.colors.ash800 }}>{title}</Text>
        <div style={{
          height: 'max',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Text strong style={{ fontSize: 16 }}>
            {showPreview ? truncateString(value, 20) ?? '-' : ''}
          </Text>
          <EditOutlined
            style={{ color: 'gray', fontSize: 24, cursor: 'pointer' }}
            onClick={() => handler()}
          /> 
        </div>
      </WebConfigItemContainer>
    </>
  );
};

const WebConfigs = () => {

  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);

  const [adminFee, setAdminFee] = React.useState<AppConfigProps>(initAppConfig);
  const [phoneNumber, setPhoneNumber] =
    React.useState<AppConfigProps>(initAppConfig);
  const [email, setEmail] = React.useState<AppConfigProps>(initAppConfig);
  const [aboutUs, setAboutUs] = React.useState<AppConfigProps>(initAppConfig);
  const [aboutRefund, setAboutRefund] =
    React.useState<AppConfigProps>(initAppConfig);

  const [isModalUpdateAdminFeeVisible, setIsModalUpdateAdminFeeVisible] =
    React.useState<boolean>(false);
  const [isModalUpdatePhoneNumberVisible, setIsModalUpdatePhoneNumberVisible] =
    React.useState<boolean>(false);
  const [isModalUpdateEmailVisible, setIsModalUpdateEmailVisible] =
    React.useState<boolean>(false);
  const [isModalUpdateAboutUsVisible, setIsModalUpdateAboutUsVisible] =
    React.useState<boolean>(false);
  const [isModalUpdateAboutRefundVisible, setIsModalUpdateAboutRefundVisible] =
    React.useState<boolean>(false);

  const [newAdminFee, setnewAdminFee] =
    React.useState<AppConfigProps>(initAppConfig);
  const [newPhoneNumber, setnewPhoneNumber] =
    React.useState<AppConfigProps>(initAppConfig);
  const [newEmail, setnewEmail] = React.useState<AppConfigProps>(initAppConfig);
  const [newAboutUs, setNewAboutUs] =
    React.useState<AppConfigProps>(initAppConfig);
  const [newAboutRefund, setNewAboutRefund] =
    React.useState<AppConfigProps>(initAppConfig);

  // const [userIds, setUserIds] = React.useState<string>();
  // const [userIdsArr, setUserIdsArr] = React.useState<any[]>([]);

  const handleInitAdminFee = async () => {
    const raw = await httpRequest.get('/app-configs/ADMIN_FEE');
    // console.log(raw);
    setAdminFee(raw.data.payload);
  };

  const handleInitAboutUs = async () => {
    const raw = await httpRequest.get('/app-configs/ABOUT_US');
    setAboutUs(raw.data.payload);
  };

  const handleInitAboutRefund = async () => {
    const raw = await httpRequest.get('/app-configs/ABOUT_REFUND');
    setAboutRefund(raw.data.payload);
  };

  const handleInitPhoneNumber = async () => {
    const raw = await httpRequest.get('/app-configs/WHATSAPP_PHONE_NUMBER');
    // console.log(raw);
    setPhoneNumber(raw.data.payload);
  };

  const handleInitEmail = async () => {
    const raw = await httpRequest.get('/app-configs/EMAIL_ADDRESS');
    // console.log(raw);
    setEmail(raw.data.payload);
  };

  React.useEffect(() => {
    handleInitAdminFee();
    handleInitPhoneNumber();
    handleInitEmail();
    handleInitAboutUs();
    handleInitAboutRefund();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAppConfig = async (config: string) => {
    // console.log(config);
    try {
      setIsLoadingAction(true);
      const raw = await httpRequest.patch(`/app-configs/${config}`, {
        value:
          config.toUpperCase() === 'ADMIN_FEE'
            ? newAdminFee.value
            : config.toUpperCase() === 'WHATSAPP_PHONE_NUMBER'
            ? newPhoneNumber.value
            : config.toUpperCase() === 'EMAIL_ADDRESS'
            ? newEmail.value
            : config.toUpperCase() === 'ABOUT_US'
            ? newAboutUs.value
            : newAboutRefund.value,
      });

      config.toUpperCase() === 'ADMIN_FEE'
        ? setAdminFee(newAdminFee)
        : config.toUpperCase() === 'WHATSAPP_PHONE_NUMBER'
        ? setPhoneNumber(newPhoneNumber)
        : config.toUpperCase() === 'EMAIL_ADDRESS'
        ? setEmail(newEmail)
        : config.toUpperCase() === 'ABOUT_US'
        ? setAboutUs(newAboutUs)
        : setAboutRefund(newAboutRefund);

      console.log(raw);

      message.success('Data updated');
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setIsModalUpdateAdminFeeVisible(false);
      setIsModalUpdatePhoneNumberVisible(false);
      setIsModalUpdateEmailVisible(false);
      setIsModalUpdateAboutUsVisible(false);
      setIsModalUpdateAboutRefundVisible(false);
      setIsLoadingAction(false);
    }
  };


  return (
    <React.Fragment>
      <HeaderSection
        icon={<AntDesignOutlined />}
        title="Web Config"
        rightAction={<></>}
      />
      <div style={{ padding: '0px 20px'}}>
        {/* <button onClick={() => console.log(data)}>check Data</button> */}

        <WebConfigContainer>
          <WebConfigItem
            title={'Admin Fee'}
            value={priceFormat(adminFee.value) ?? '-'}
            handler={() => setIsModalUpdateAdminFeeVisible(true)}
          />
          <WebConfigItem
            title={'Phone Number'}
            value={phoneNumber.value ?? '-'}
            handler={() => setIsModalUpdatePhoneNumberVisible(true)}
          />
          <WebConfigItem
            title={'Email Address'}
            value={email.value ?? '-'}
            handler={() => setIsModalUpdateEmailVisible(true)}
          />
        </WebConfigContainer>
      </div>

      <Modal
        title="Edit Admin Fee"
        open={isModalUpdateAdminFeeVisible}
        onOk={() => handleChangeAppConfig('ADMIN_FEE')}
        onCancel={(e: any) => {
          setIsModalUpdateAdminFeeVisible(false);
          // console.log(e.target.value);
        }}
        okText="Save"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <Text
          style={{
            fontSize: 14,
            color: 'gray',
            display: 'block',
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          Amount
        </Text>
        <Input
          placeholder="Enter amount"
          defaultValue={adminFee.value}
          addonAfter={<div>Rp</div>}
          onChange={(e: any) =>
            setnewAdminFee({ ...newAdminFee, value: e.target.value })
          }
        />
      </Modal>

      <Modal
        title="Edit Phone Number"
        open={isModalUpdatePhoneNumberVisible}
        onOk={() => handleChangeAppConfig('WHATSAPP_PHONE_NUMBER')}
        onCancel={(e: any) => {
          setIsModalUpdatePhoneNumberVisible(false);
          // console.log(e.target.value);
        }}
        okText="Save"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <Text
          style={{
            fontSize: 14,
            color: 'gray',
            display: 'block',
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          Amount
        </Text>
        <Input
          placeholder="Enter phone number"
          defaultValue={phoneNumber.value}
          // addonAfter={<div>Rp</div>}
          onChange={(e: any) =>
            setnewPhoneNumber({
              ...newPhoneNumber,
              value: e.target.value,
            })
          }
        />
      </Modal>

      <Modal
        title="Email Address"
        open={isModalUpdateEmailVisible}
        onOk={() => handleChangeAppConfig('EMAIL_ADDRESS')}
        onCancel={(e: any) => {
          setIsModalUpdateEmailVisible(false);
          // console.log(e.target.value);
        }}
        okText="Save"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <Text
          style={{
            fontSize: 14,
            color: 'gray',
            display: 'block',
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          Email
        </Text>
        <Input
          defaultValue={email.value}
          placeholder="Enter email address"
          onChange={(e: any) =>
            setnewEmail({
              ...newEmail,
              value: e.target.value,
            })
          }
        />
      </Modal>

    </React.Fragment>
  );
};

const WebConfigItemContainer = styled.div`
  gap: 40px;
  border: 1px solid ${theme.colors.ash500};
  border-radius: 5px;
  padding: 15px;
  // min-width: 250px;
  width: 30%;
  flex-grow: 1;
`;

const WebConfigContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  // background-color: aqua;
`;

export default WebConfigs;

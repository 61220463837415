import {
  CreditCardFilled,
  LineChartOutlined,
  RightOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Typography,
  DatePicker,
} from "antd";
import React, { useState } from "react";
import ReportCard from "../../components/ReportCard";
import LatestTransaction from "../../components/Report/LatestTransaction";
import MostPurchased from "../../components/Report/MostPurchased";
import TransactionStatistic from "../../components/Report/TransactionStatistic";
import { useQuery } from "../transaction";
import { httpRequest } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import {
  FetchAllPaymentAccountReportResponse,
  PaymentConfigProps,
  ReportPaymentAccountProps,
} from "../../types/paymentConfig.type";
import { nFormatter } from "../../helpers/priceFormat";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const Reports = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const paymentAccountId = query.get("paymentAccountId");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentAccountData, setPaymentAccountData] =
    useState<ReportPaymentAccountProps>();
  const [monthlyData, setMonthlyData] = useState<any>([]);
  const [totalMonthlyTransaction, setTotalMonthlyTransaction] =
    useState<number>(0);
  const [totalYearlyTransaction, setTotalYearlyTransaction] =
    useState<number>(0);
  const [filterStart, setFilterStart] = useState<String | null>();
  const [filterEnd, setFilterEnd] = useState<String | null>();

  const getAllPaymentAccountId = async () => {
    const raw = await httpRequest.get<any>("/payment-accounts");
    const primaryAccount = raw.data.payload.results.find(
      (account: PaymentConfigProps) => account.isActive === true
    ).paymentAccountId;
    navigate(`?paymentAccountId=${primaryAccount}`);
  };

  React.useEffect(() => {
    const getAllPaymentAccountId = async () => {
      const raw = await httpRequest.get<any>("/payment-accounts");
      const primaryAccount = raw.data.payload.results.find((account : PaymentConfigProps) => account.isActive === true).paymentAccountId
      navigate(`?paymentAccountId=${primaryAccount}`);
    };
    !paymentAccountId && getAllPaymentAccountId();
  }, [paymentAccountId]);

  React.useEffect(() => {
    const fetchPaymentAccountDetail = async () => {
      const paymentAccountId2 = query.get("paymentAccountId");
      try {
        let response =
          await httpRequest.get<FetchAllPaymentAccountReportResponse>(
            `/transactions/report/total-transaction-by-payment-accounts?paymentAccountIds=${paymentAccountId2}`
          );
        setPaymentAccountData(response.data.payload.results[0]);
        console.log("responsenya", response)
      } catch (error) {}
    };

    const fetchMonthlyData = async () => {
      try {
        const paymentAccountId2 = query.get("paymentAccountId");
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const response = await httpRequest.get<any>(
          `/transactions/report/total-transaction-one-year-by-payment-account?paymentAccountId=${paymentAccountId2}&year=${year}`
        );
        console.info("resyear", response);
        setMonthlyData(response.data.payload);
        const filteredMonthData = response.data.payload.results.filter((data: any) => data.month === month)
        setTotalMonthlyTransaction(
          filteredMonthData.reduce(
            (acc: number, data: any) => acc + data.sumTotalFinalPrice,
            0
          )
        );
        console.log("response", response)
        setTotalYearlyTransaction(
          response.data.payload.results.reduce(
            (acc: number, data: any) => acc + data.sumTotalFinalPrice,
            0
          )
        );
        
        console.log("setmonthlydata", response.data.payload.results);
      } catch (error) {
        console.info('error', error)
      }
    };
    console.log("yearly", totalYearlyTransaction)
    fetchPaymentAccountDetail();
    fetchMonthlyData()
  }, [paymentAccountId]);

  const detailNavigate = () => {
    navigate(`/report/detail?paymentAccountId=${paymentAccountId}`);
  };

  const transactionNavigate = () => {
    navigate(`/transactions?paymentAccountId=${paymentAccountId}`);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDownloadReport = async () => {
    const response = await httpRequest.get<any>(
      `transactions/report/download?paymentAccountId=${paymentAccountId}${
        filterStart && filterEnd
          ? `&filterStartAt=${filterStart}&filterEndAt=${filterEnd}`
          : ""
      }`,
      {
        responseType: "blob",
      }
    );

    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition.split("attachment; filename=")[1];

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    console.log(response);
  };

  React.useEffect(() => {
    console.log(filterStart);
    console.log(filterEnd);
  }, [filterEnd, filterStart]);
  return (
    <React.Fragment>
      <Row gutter={[18, 20]} style={{ margin: 18 }}>
        <Col xs={12} lg={6}>
          <ReportCard
            title={
              <div>
                <div>
                  <Text style={{ fontSize: 12 }}>
                    {paymentAccountData?.label || "Payment Account"}
                  </Text>
                </div>
                <div style={{ display: "flex", verticalAlign: "text-top" }}>
                  <Text strong>Rp </Text>
                  <Text strong style={{ fontSize: 22 }}>
                    {" "}
                    {nFormatter(paymentAccountData?.sumTotalFinalPrice || 0, 3)}
                  </Text>
                </div>
              </div>
            }
            extra={
              <div>
                <Avatar
                  shape="square"
                  icon={<CreditCardFilled style={{ color: "#FD7014" }} />}
                  style={{ backgroundColor: "#FFE8DA" }}
                />
              </div>
            }
          >
            <>
              <Divider style={{ margin: "13px 0" }} />
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={detailNavigate}
              >
                <Text type="secondary" style={{ fontSize: 10 }}>
                  View transation detail
                </Text>
                <RightOutlined style={{ fontSize: 10, color: "#C5CDDB" }} />
              </div>
            </>
          </ReportCard>
        </Col>
        <Col xs={12} lg={6}>
          <ReportCard
            title={
              <div>
                <div>
                  <Text style={{ fontSize: 12 }}>Total Transaction (YTD)</Text>
                </div>
                <div style={{ display: "flex", verticalAlign: "text-top" }}>
                  <Text strong>Rp </Text>
                  <Text strong style={{ fontSize: 22 }}>
                    {" "}
                    {nFormatter(totalYearlyTransaction, 3)}
                  </Text>
                </div>
              </div>
            }
            extra={
              <div>
                <Avatar
                  shape="square"
                  icon={<LineChartOutlined style={{ color: "#40B822" }} />}
                  style={{ backgroundColor: "#D9F8D1" }}
                />
              </div>
            }
          >
            <>
              <Divider style={{ margin: "13px 0" }} />
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={transactionNavigate}
              >
                <Text type="secondary" style={{ fontSize: 10 }}>
                  View transation detail
                </Text>
                <RightOutlined style={{ fontSize: 10, color: "#C5CDDB" }} />
              </div>
            </>
          </ReportCard>
        </Col>
        <Col xs={12} lg={6}>
          <ReportCard
            title={
              <div>
                <div>
                  <Text style={{ fontSize: 12 }}>Total Transaction (MTD)</Text>
                </div>
                <div style={{ display: "flex", verticalAlign: "text-top" }}>
                  <Text strong>Rp </Text>
                  <Text strong style={{ fontSize: 22 }}>
                    {" "}
                    {nFormatter(totalMonthlyTransaction, 3)}
                  </Text>
                </div>
              </div>
            }
            extra={
              <div>
                <Avatar
                  shape="square"
                  icon={<LineChartOutlined style={{ color: "#A75BE3" }} />}
                  style={{ backgroundColor: "#EFE2FA" }}
                />
              </div>
            }
          >
            <>
              <Divider style={{ margin: "13px 0" }} />
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={transactionNavigate}
              >
                <Text type="secondary" style={{ fontSize: 10 }}>
                  View transation detail
                </Text>
                <RightOutlined style={{ fontSize: 10, color: "#C5CDDB" }} />
              </div>
            </>
          </ReportCard>
        </Col>
        <Col xs={12} lg={6}>
          <ReportCard
            title={
              <div>
                <div>
                  <Text style={{ fontSize: 12 }}>Download Report</Text>
                </div>
                <div style={{ display: "flex", verticalAlign: "text-top" }}>
                  <Text type="secondary" style={{ fontSize: 10 }}>
                    Export report to spreadsheet
                  </Text>
                </div>
              </div>
            }
            extra={
              <div>
                <Avatar
                  shape="square"
                  icon={
                    <VerticalAlignBottomOutlined style={{ color: "#20A1F5" }} />
                  }
                  style={{ backgroundColor: "#E2F1FF" }}
                />
              </div>
            }
          >
            <>
              <Divider style={{ margin: "13px 0", border: "none" }} />
              <Button
                block
                size="small"
                style={{ border: "1px solid #E8ECF3" }}
                onClick={showModal}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    verticalAlign: "middle",
                  }}
                >
                  <Text
                    style={{ fontSize: 12, color: "#20A1F5", fontWeight: 600 }}
                    strong
                  >
                    Download Report
                  </Text>
                  <RightOutlined
                    style={{
                      fontSize: 12,
                      color: "#20A1F5",
                      fontWeight: 600,
                      margin: "auto 0",
                    }}
                  />
                </div>
              </Button>
            </>
          </ReportCard>
        </Col>
        <Col span={12}>
          <MostPurchased />
        </Col>
        <Col span={12}>
          <TransactionStatistic />
        </Col>
        <Col span={24}>
          <LatestTransaction />
        </Col>
      </Row>
      <Modal
        className="report-export-modal"
        title={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text strong>Download Report</Text>
            <Text type="secondary" style={{ fontSize: 14, fontWeight: 400 }}>
              Export report to spreadsheet
            </Text>
          </div>
        }
        // footer={null}
        open={isModalOpen}
        okText="Export"
        onOk={() => handleDownloadReport()}
        onCancel={handleCancel}
        okButtonProps={{ title: "Download Report File" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text type="secondary" strong>
            Choose Date Range
          </Text>
          <RangePicker
            placeholder={["DD MMM YYYY", "DD MMM YYYY"]}
            suffixIcon={null}
            onChange={(e) => {
              setFilterStart(e && e[0]?.toISOString());
              setFilterEnd(e && e[1]?.toISOString());
            }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Reports;

import { DatePicker, List, Typography } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import { useEffect, useState } from "react";
import ReportCard from "../../components/ReportCard";
import { httpRequest } from "../../helpers/api";
import { useQuery } from "../../screens/transaction";

const { Text } = Typography;

const MostPurchased = () => {
  const [yearFilter, setYearFilter] = useState<string>(moment().year().toString())
  const [sortedProducts, setSortedProducts] = useState<any>([])
  const query = useQuery();

  const paymentAccountId = query.get("paymentAccountId");

  useEffect(() => {
    const fetchMostFeaturedProducts = async () => {
      try {
        const response = await httpRequest.get<any>(`/transactions/report/total-transaction-by-products?paymentAccountId=${paymentAccountId}&year=${yearFilter}`);
        let result = response.data.payload.results.sort((a: any, b: any) => b.sumQty - a.sumQty).slice(0, 5);
        setSortedProducts(result)
      } catch (error) {
        
      }
    }
    fetchMostFeaturedProducts();
  }, [paymentAccountId, yearFilter])

  const onSelectYearFilter = (value: string) => {
    setYearFilter(value)
  }

  const disabledYear: RangePickerProps['disabledDate'] = (current) => {
    // can not select year before this year
    return current && current > moment().startOf('year');
  }
  
  return (
    <ReportCard
      title={
        <div>
          <div>
            <Text strong style={{fontSize: 14}}>Most Purchased Products</Text>
          </div>
          <div style={{display:"flex", verticalAlign: "text-top"}}>
            <Text type="secondary" style={{fontSize: 10}}>Pellentesque habitant morbi tristique senectus</Text>
          </div>
        </div>
      }
      extra={<div>
        <DatePicker
          picker="year"
          size="small"
          style={{ width: 100, fontSize: 10 }}
          disabledDate={disabledYear}
          dropdownClassName="year-picker"
          onChange={(date, dateString) => onSelectYearFilter(dateString)}
          format={"YYYY"}
          defaultValue={moment().startOf('year')}
        />
      </div>}
    >
      <>
        <List
          dataSource={sortedProducts}
          renderItem={(item:{metaProduct:any, sumQty: number}, index) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      <Text type="secondary" style={{fontSize: 12, margin: 16}}>{index+1}</Text>
                    </div>
                    <div style={{flex: 1}}>
                    <Text style={{fontSize: 12, textAlign:"left"}}>{item.metaProduct.productName}</Text>  
                    </div>
                    <div>
                      <Text style={{fontSize: 12}}>{item.sumQty} </Text>
                      <Text type="secondary" style={{fontSize: 12}}>purchased</Text>
                    </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </>

    </ReportCard>
  )
}

export default MostPurchased;
import { useContext, useState } from 'react';
import { removeToken, saveToken, getLoginData } from '../helpers/auth';
import { UserProperties } from '../types/user.type';
import { EAuthStatus } from '../types/auth.type';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import AuthContext from '../context/AuthContext';

export default function useAuth() {
  const { auth, setAuth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  message.config({
    maxCount: 1,
  });

  const saveProfile = async (data: UserProperties) => {
    setIsLoading(true);

    const formData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      userType: data.userType,
      status: data.status,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
    }

    try {
      const resultUpdate = await httpRequest.patch<IHttpResponse<UserProperties>>('/users/' + auth.user.userId, formData);
      setAuth({
        ...auth,
        user: resultUpdate.data.payload
      });
      setIsLoading(false);
      message.success('Update profile successfully')
    } catch(err) {
      message.error(getErrorMessage(err));
      setIsLoading(false);
    }
  }

  const login = async ({token}:{token: string}) => {
    try {
      const loginData = await getLoginData(token);
      saveToken(token);
      setAuth({
        status: EAuthStatus.LOGGED_IN,
        ...loginData
      });
      message.success('Login successfully.');
      setIsLoading(false);
      
    } catch (err) {
      message.error('Login failed. ' + getErrorMessage(err));
      setIsLoading(false);
    }
  }

  const logout = () => {
    removeToken();
    setAuth({
      status: EAuthStatus.LOGGED_OUT
    });
  };

  return {
    isLoading,
    isLoggedIn: auth.status === EAuthStatus.LOGGED_IN,
    user: auth.user,
    role: auth.role,
    login,
    logout,
    saveProfile,
  };
}
// import AppLayout from "../layout/AppLayout";
import { AntDesignOutlined, SearchOutlined } from "@ant-design/icons";
import { Pagination, Table, Input, Select, Tag, Typography } from "antd";
import HeaderSection from "../../components/HeaderSection";
import { formatDate, PAGE_SIZE_OPTIONS } from "../../helpers/constant";
import useFetchList from "../../hooks/useFetchList";
import React from "react";
import { ContainerFilter } from "../product";
import { ETransactionStatus, TransactionProps } from "../../types/transaction";
import { UserProperties } from "../../types/user.type";
import { useNavigate, useParams } from "react-router-dom";
import { httpRequest } from "../../helpers/api";
import { BaseResponseProps } from "../../types/config.type";
import { CustomRangePicker } from "../transaction";
import moment from "moment";

const { Text } = Typography;

export const tagStyle = [
  {
    color: "#31d63a",
  },
  {
    color: "#D81F64",
  },
  {
    color: "#F1B203",
  },
];

export const listOfStatusFailed = [
  ETransactionStatus.expired,
  ETransactionStatus.payment_failed,
  ETransactionStatus.rejected,
];

export const listOfStatusSuccess = [
  ETransactionStatus.paid,
  ETransactionStatus.process,
  ETransactionStatus.received,
  ETransactionStatus.sent,
];

const Transaction = () => {
  const { buyerEmail } = useParams();
  const navigate = useNavigate();
  // const [email, setEmail] = React.useState<string>("");

  // const getUser = async () => {
  //   const raw = await httpRequest.get<BaseResponseProps<UserProperties>>(
  //     `/users/${userId}`
  //   );
  //   setEmail(raw.data.payload.email);
  //   console.log(raw.data.payload.email);
  // };

  const {
    isLoading,
    data,
    pagination,
    // setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    // setIsLoading,
  } = useFetchList<TransactionProps>({
    endpoint: `transactions`,
    initialQuery: { buyerEmail: buyerEmail },
  });

  // React.useEffect(() => {
  //   getUser();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userId, data]);

  const handleClickDetail = (e: TransactionProps) => {
    navigate(`/transactions/${e.transactionId}`);
  };
  const { Option } = Select;

  const handleTransactionStatusFilterChange = (
    transactionStatus: ETransactionStatus | string
  ) => {
    if (transactionStatus === "all") {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: "",
      }));
    } else if (transactionStatus) {
      setQuery((oldVal) => ({
        ...oldVal,
        transactionStatus: transactionStatus,
      }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, transactionStatus: null }));
    }
  };

  function formatRupiah(angka: string, prefix: string) {
    let number_string = angka.replace(/[^,\d]/g, "").toString();
    let split = number_string.split(",");
    let sisa = split[0].length % 3;
    let rupiah = split[0].substring(0, sisa);
    let ribuan = split[0].substring(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      const separator = sisa ? "." : "";
      rupiah += separator + ribuan.join(".");
    }

    rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
    return prefix === undefined
      ? rupiah
      : rupiah
      ? prefix + rupiah + ",00"
      : "";
  }

  const handleDateRangeFilterChange = (e: any) => {
    if (e !== null) {
      // console.log(e);
      const fromDate = moment(e[0]).toISOString();
      const toDate = moment(e[1]).toISOString();
      setQuery((oldVal) => ({
        ...oldVal,
        filterStartAt: fromDate,
        filterEndAt: toDate,
      }));
    } else {
      setQuery((oldVal) => ({
        ...oldVal,
        filterStartAt: null,
        filterEndAt: null,
      }));
    }
  };

  const columns = [
    {
      title: "TRANSACTION ID",
      key: "transactionCode",
      dataIndex: "transactionCode",
      sorter: (a: any, b: any) => {
        return `${a.transactionCode}`.localeCompare(`${b.transactionCode}`);
      },
      render: (text: string, record: TransactionProps) => {
        return (
          <div className="table-link" onClick={() => handleClickDetail(record)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "PRODUCT",
      key: "items",
      dataIndex: ["transactionItems", 0, "metaProduct", "productName"],
      sorter: (a: any, b: any) => {
        return `${a.transactionItems[0]?.metaProduct?.productName}`.localeCompare(
          `${b.transactionItems[0]?.metaProduct?.productName}`
        );
      },
      render: (text: string, record: TransactionProps, index: number) => {
        const itemsLength = data[index]?.transactionItems?.length;
        return (
          <div>
            <div>{text}</div>
            <div>
              {itemsLength && itemsLength > 1
                ? `+${itemsLength - 1} More`
                : null}
            </div>
          </div>
        );
      },
    },
    {
      title: "CUSTOMER",
      key: "transactionCode",
      dataIndex: ["metaBuyer", "name"],
      sorter: (a: any, b: any) => {
        return `${a.metaBuyer.name}`.localeCompare(`${b.metaBuyer.name}`);
      },
      render: (text: string, record: TransactionProps, index: number) => {
        // console.log(index);
        return (
          <div>
            <Text strong>{text}</Text>
            <div>{data[index]?.metaBuyer?.email}</div>
          </div>
        );
      },
    },
    {
      title: "TOTAL",
      key: "totalFinalPrice",
      dataIndex: "totalFinalPrice",
      sorter: (a: any, b: any) => {
        return a.totalFinalPrice - b.totalFinalPrice;
      },
      render: (price: number, record: TransactionProps) => {
        return (
          <div>{formatRupiah(record.totalFinalPrice.toString(), "Rp. ")}</div>
        );
      },
    },
    {
      title: "STATUS",
      key: "transactionStatus",
      dataIndex: "transactionStatus",
      render: (text: string, record: TransactionProps) => {
        return (
          <Tag
            style={{
              fontWeight: "bold",
              borderRadius: 5,
              border: `2px solid ${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? `${tagStyle[1].color}40`
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? `${tagStyle[0].color}40`
                  : `${tagStyle[2].color}40`
              }`,
              color: `${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? tagStyle[1].color
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? tagStyle[0].color
                  : tagStyle[2].color
              }`,
              backgroundColor: `${
                listOfStatusFailed.includes(record.transactionStatus)
                  ? `${tagStyle[1].color}40`
                  : listOfStatusSuccess.includes(record.transactionStatus)
                  ? `${tagStyle[0].color}40`
                  : `${tagStyle[2].color}40`
              }`,
              marginBottom: "7%",
            }}
          >
            {record.transactionStatus === ETransactionStatus.created
              ? "Waiting Payment"
              : record.transactionStatus}
          </Tag>
        );
      },
    },
    {
      title: "ORDER DATE",
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();
        return dateA - dateB;
      },
      render: (price: number, record: TransactionProps) => {
        return <div>{formatDate(record.createdAt)}</div>;
      },
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title="Transactions"
        subtitle="Manage your transactions data"
      />
      <div style={{ padding: "0px 20px" }}>
        {/* <Button onClick={() => console.log(data)}>Show data</Button> */}
        <ContainerFilter>
          {/* <h4 style={labelStyle}>Search</h4> */}
          <Input
            // size="small"
            placeholder="Search by Transaction ID"
            style={{ width: "100%" }}
            prefix={<SearchOutlined />}
            allowClear
            onChange={(e) => setSearch(e.target.value)}
          />

          {/* <h4 style={labelStyle}>Status</h4> */}
          <Select
            // size="small"
            allowClear
            style={{ width: 200 }}
            onChange={handleTransactionStatusFilterChange}
            placeholder="Transaction Status"
          >
            <Option value="all">All</Option>
            <Option value={ETransactionStatus.created}>Waiting Payment</Option>
            <Option value={ETransactionStatus.canceled}>Canceled</Option>
            <Option value={ETransactionStatus.expired}>Expired</Option>
            <Option value={ETransactionStatus.paid}>Paid</Option>
            <Option value={ETransactionStatus.payment_failed}>
              Payment Failed
            </Option>
            <Option value={ETransactionStatus.process}>Process</Option>
            <Option value={ETransactionStatus.received}>Received</Option>
            <Option value={ETransactionStatus.refunded}>Refunded</Option>
            <Option value={ETransactionStatus.rejected}>Rejected</Option>
            <Option value={ETransactionStatus.sent}>Sent</Option>
          </Select>
          <CustomRangePicker onChange={handleDateRangeFilterChange} />
        </ContainerFilter>

        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
        <Pagination
          current={pagination.page}
          total={pagination.totalData}
          defaultPageSize={pagination.perPage}
          pageSize={pagination.perPage}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onShowSizeChange={(_current, size) => {
            changeLimit(size);
          }}
          showSizeChanger={true}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={changePage}
        />
      </div>
    </React.Fragment>
  );
};

export default Transaction;

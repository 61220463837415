const priceFormat = (price?: string|number)=>{
	if(price === undefined){
		return '-';
	}
	var formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		maximumFractionDigits: 0
	});
	return formatter.format(Number(price));
}

function formatRupiah(angka: string, prefix: string) {
	let number_string = angka.replace(/[^,\d]/g, "").toString();
	let split = number_string.split(",");
	let sisa = split[0].length % 3;
	let rupiah = split[0].substring(0, sisa);
	let ribuan = split[0].substring(sisa).match(/\d{3}/gi);

	// tambahkan titik jika yang di input sudah menjadi angka ribuan
	if (ribuan) {
		const separator = sisa ? "." : "";
		rupiah += separator + ribuan.join(".");
	}

	rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
	return prefix === undefined
		? rupiah
		: rupiah
		? prefix + rupiah + ",00"
		: "";
}

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export {
	priceFormat,
	formatRupiah,
	nFormatter
}
import React from 'react';
import {
  AntDesignOutlined
} from '@ant-design/icons';
import {
  message,
  Select,
  Typography,
  Button
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import {
  AppConfigProps,
  initAppConfig,
  PaymentConfigProps,
} from '../../types/paymentConfig.type';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { theme } from "../../assets/theme";
import { ContainerFilter } from '../product';
import {
  iconFileRemove as IconFileRemove
} from "../../assets/icons/screens";

interface ResponseProps extends BaseResponseProps<PaymentConfigProps> {}

const { Text, Title } = Typography;
const { Option } = Select;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
];

const AboutUs = () => {

  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
  const [aboutUs, setAboutUs] =
    React.useState<AppConfigProps>(initAppConfig);
  const [newAboutUs, setNewAboutUs] = React.useState<AppConfigProps>(initAppConfig);  
  // const [userIds, setUserIds] = React.useState<string>();
  // const [userIdsArr, setUserIdsArr] = React.useState<any[]>([]);

  console.log("about us: ", aboutUs.value)
  console.log("new about us: ", aboutUs.value)

  const handleInitAboutUs = async () => {
    const raw = await httpRequest.get('/app-configs/ABOUT_US');
    setAboutUs(raw.data.payload);
    setNewAboutUs(raw.data.payload);
    setIsDataLoaded(true);
  };

  React.useEffect(() => {
    handleInitAboutUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAppConfig = async () => {
    try {
      setIsLoadingAction(true);
      const raw = await httpRequest.patch('/app-configs/ABOUT_US', {
        value: newAboutUs.value,
      });
      setAboutUs(newAboutUs)
      console.log(raw);
      message.success('Data updated');
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setIsLoadingAction(false);
    }
  };


  return (
    <React.Fragment>
      <HeaderSection
        icon={<AntDesignOutlined />}
        title="About Us"
        rightAction={<></>}
      />
      <div style={{ padding: '0px 24px' }}>
        {/* <button onClick={() => console.log(data)}>check Data</button> */}

        <ContainerFilter
          style={{
            justifyContent: 'space-between'
          }}
        >
          <div 
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <IconFileRemove/>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                color: 'black',
                display: 'block'
              }}
            >
              About Us
            </Text>
            <div 
              style={{ 
                width: '1px',
                height: '25px',
                backgroundColor: theme.colors.ash500,
                margin: '0 5px'
              }}
            />
            <Text
              style={{
                fontSize: 12,
                fontWeight: '400',
                color: '#768499',
                display: 'block'
              }}
            >
              Excepteur sint occaecat cupidatat non proident
            </Text>
          </div>
          <Button type="primary" onClick={handleChangeAppConfig} loading={isLoadingAction}>
            Save
          </Button>
        </ContainerFilter>

        {isDataLoaded && ( 
          <ReactQuill
            style={{ padding: "15px 20px" }}
            theme="snow"
            defaultValue={aboutUs.value}
            onChange={(val) =>
              setNewAboutUs({
                ...newAboutUs,
                value: val,
              })
            }
            modules={quillModules}
            formats={quillFormats}
          />
        )}

      </div>
    </React.Fragment>
  );
};

export default AboutUs;
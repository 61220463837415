import React from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import {
  AntDesignOutlined,
  EditOutlined,
  MoreOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Pagination,
  Space,
  Table,
  Switch,
  Dropdown,
  Menu,
  Modal,
  message,
  Input,
  Select,
  Typography,
  Button,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import useFetchList from '../../hooks/useFetchList';
import { formatDate, PAGE_SIZE_OPTIONS } from '../../helpers/constant';
import {
  AppConfigProps,
  initAppConfig,
  initialPaymentConfig,
  PaymentConfigProps,
} from '../../types/paymentConfig.type';
import { priceFormat } from '../../helpers/priceFormat';
import { ContainerFilter } from '../product';
import 'react-quill/dist/quill.snow.css';
import { loadavg } from 'os';
import { TransactionProps } from '../../types/transaction';

interface ResponseProps extends BaseResponseProps<PaymentConfigProps> {}

const { Text, Title } = Typography;
const { Option } = Select;

const PaymentConfigs = () => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [isModalUpdateStatusVisible, setIsModalUpdateStatusVisible] = React.useState<boolean>(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = React.useState<boolean>(false);
  const [tmpData, setTmpData] = React.useState<any>(initialPaymentConfig);
  const { paymentConfigId } = useParams<any>();
  const [totalTransactions, setTotalTransactions] = React.useState<Array<{ id: string; count: string }>>([]);

  // const [userIds, setUserIds] = React.useState<string>();
  // const [userIdsArr, setUserIdsArr] = React.useState<any[]>([]);
  const {
    isLoading,
    data,
    pagination,
    setData,
    setSearch,
    setQuery,
    changePage,
    changeLimit,
    fetchList,
  } = useFetchList<PaymentConfigProps>({
    endpoint: 'payment-accounts',
    limit: 10,
  });
  console.log("data111: ", data);
  const handleCreatePaymentAccount = async () => {
    navigate('/payment-config/add');
  };


  const getTotalTransactions = async () => {
    try {
      console.log("Datagt:", data);
      const ids = data.map((item) => item.paymentAccountId);
      console.log("idsgt:---", data);
      // Use Promise.all to make multiple requests concurrently
      const requests = ids.map(async (id) => {
        console.log("idgt:", id); 
        const raw = await httpRequest.get<any>(
          `/transactions/report/total-transaction-by-payment-accounts?paymentAccountIds=${id}`
        );
        console.log("fetching total trx ...");
        console.log("raw:", raw);
        const count = raw.data?.payload?.results[0]?.countTransaction || 0;
        return { id, count };
      });
      const results = await Promise.all(requests);
      console.log("Resultsgt:", results);
      // Use a callback to update totalTransactions without triggering a re-render
      setTotalTransactions((prevTotalTransactions) => [...prevTotalTransactions, ...results]);
      return;
    } catch (error) {
      console.error("Error fetching total transactions:", error);
      // Handle error as needed
      throw error;
    }
  };
  
  React.useEffect(() => {
    getTotalTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  

  const handleStatusChange = async () => {
    try {
      setIsLoadingAction(true);
      let newData = [];

      const res = await httpRequest.patch<ResponseProps>(
        'payment-accounts/' + tmpData.paymentAccountId + '/activate',
        {
          isActive: !tmpData.isActive,
        },
      );

      newData = data.map((item) => {
        if (item.paymentAccountId === res.data.payload.paymentAccountId) {
          return {
            ...item,
            isActive: res.data.payload.isActive,
          };
        }
        return item;
      });

      setData(newData);

      message.success('Success change ' + tmpData.label + ' status.');
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsLoadingAction(false);
      setIsModalUpdateStatusVisible(false);
      setTmpData(initialPaymentConfig);
      window.location.reload();
    }
  };

  const handleChangeStatus = (status: string) => {
    if (status !== 'all') {
      setQuery((oldVal) => ({ ...oldVal, isActive: status }));
    } else {
      setQuery((oldVal) => ({ ...oldVal, isActive: '' }));
    }
  };

  const handleClickAction = (props: PaymentConfigProps, key: string) => {
    if (key === 'edit') {
      navigate(`/payment-config/${props.paymentAccountId}/edit`);
    } else if (key === 'delete') {
      setIsModalDeleteVisible(true);
      setTmpData(props);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoadingAction(true);
      const res = await httpRequest.delete<
        BaseResponseProps<{ isSuccess: boolean }>
      >('payment-accounts/' + tmpData.paymentAccountId);

      if (res.data.payload.isSuccess) {
        message.success('Success delete ' + tmpData.label);
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsLoadingAction(false);
      setIsModalDeleteVisible(false);
      setTmpData(initialPaymentConfig);
      fetchList();
    }
  };

  // Transactions Counter

  // React.useEffect(() => {
  //   const idsArr = data.map((item: PaymentConfigProps, index: number) => {
  //     return item.paymentAccountId;
  //   });
  //   const idsStr = idsArr.join(",");
  //   setUserIds(idsStr);
  // }, [data]);

  // const getTransactionsByUserIds = async () => {
  //   const raw = await httpRequest.get<BaseResponseProps<any>>(
  //     `/transactions/report/total-transaction-by-payment-accounts?paymentAccountIds=${userIds}`
  //   );
  //   const arr = await raw.data?.payload?.results;
  //   console.log(arr);
  //   setUserIdsArr(arr);
  // };

  // React.useEffect(() => {
  //   getTransactionsByUserIds();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userIds]);

  // const getQtyPropsByUserId = (userId: string) => {
  //   const qtyRaw = userIdsArr?.filter((data: any) => data.buyerId === userId);
  //   return qtyRaw[0];
  // };

  const columns = [
    {
      title: 'LABEL',
      dataIndex: 'label',
      key: 'label',
      sorter: (a: any, b: any) => {
        return `${a.label}`.localeCompare(`${b.label}`);
      },
      render: (productCode: string, record: PaymentConfigProps) => {
        return (
          <>
            {productCode ? (
              <div
                className="table-link"
                onClick={() =>
                  navigate(`/payment-config/${record.paymentAccountId}`)
                }
              >
                {productCode}
              </div>
            ) : (
              <Text italic>Not Set</Text>
            )}
          </>
        );
      },
    },
    {
      title: 'TOTAL TRANSACTIONS',
      dataIndex: 'paymentAccountId',
      key: 'paymentAccountId',
      render: (paymentAccountId: any, record: PaymentConfigProps) => {
        const thisTotalTransaction = totalTransactions.find((item) => item.id === paymentAccountId)?.count;
        return (
        <div style={{ display:"flex" }}>
          <Text style={{ marginRight: "10px" , fontWeight: "bold"}}>{thisTotalTransaction ? thisTotalTransaction : "0" }</Text>
          <div
            className="table-link"
            style={{ color: 'blue' }}
            onClick={() =>
              navigate(`${record.paymentAccountId}/transactions-list`)
            }
          >
            See transactions
          </div>
        </div>
      );
    },
  },

    {
      title: 'TRANSACTION AMOUNT / LIMIT TRANSACTION',
      dataIndex: 'maxTotalTrxAmount',
      key: 'maxTotalTrxAmount',
      sorter: (a: any, b: any) => {
        // Assuming these values are numbers, handle undefined and null
        return (a.maxTotalTrxAmount || 0) - (b.maxTotalTrxAmount || 0);
      },
      render: (text: string, record: PaymentConfigProps) => {
        const { maxTotalTrxAmount, totalAmountTransaction } = record;
        const maxPrecents = ((totalAmountTransaction ?? 0) / (maxTotalTrxAmount ?? 1) * 100).toFixed(1);
        const color = (totalAmountTransaction ?? 0) / (maxTotalTrxAmount ?? 1) * 100 > 90 ? 'red' : 'green';
        return (
          <>
            {maxTotalTrxAmount !== undefined && maxTotalTrxAmount !== null ? (
              <Text>
                <span style={{ color, fontWeight: `bold` }}>{`${priceFormat(totalAmountTransaction || 0)}`}</span>
                {` / ${priceFormat(maxTotalTrxAmount)}`} 
                <span style={{ color, fontWeight: `bold` }}>{` (${maxPrecents}%)`}</span>
              </Text>
            ) : (
              <Text italic>Not Set</Text>
            )}
          </>
        );
      },
    },
    
    {
      title: 'STATUS',
      key: 'isActive',
      dataIndex: 'isActive',
      render: (isActive: any, record: PaymentConfigProps) => {
        return (
          <Switch
            checked={isActive}
            onChange={() => {
              setIsModalUpdateStatusVisible(true);
              setTmpData(record);
            }}
          />
        );
      },
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateA - dateB;
      },
      render: (updatedAt: any) => <div>{formatDate(updatedAt)}</div>,
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: PaymentConfigProps) => (
        <Dropdown overlay={() => menu(record)} placement="bottomRight">
          <MoreOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (record: PaymentConfigProps) => (
    <Menu onClick={(e) => handleClickAction(record, e.key)}>
      <Menu.Item key="edit">Edit Payment Account</Menu.Item>
      <Menu.Item key="delete">Delete Payment Account</Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <HeaderSection
        icon={<AntDesignOutlined />}
        title="Payment Account"
        rightAction={<></>}
      />
      <div style={{ padding: '0px 20px'}}>
        {/* <button onClick={() => console.log(data)}>check Data</button> */}

        <ContainerFilter>
          <Input
            style={{ width: '100%' }}
            placeholder="Search by Product Label"
            prefix={<SearchOutlined/>}
            allowClear
            onChange={(e) =>{ setSearch(e.target.value);}}
          />
          <Select
            allowClear
            style={{ width: 250 }}
            onChange={handleChangeStatus}
            placeholder="Status"
          >
            <Option value="all">All</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
          <Space>
            <Button
              type="primary"
              // size="small"
              onClick={handleCreatePaymentAccount}
              style={{ minWidth: 139, paddingLeft: 10, paddingRight: 10 }}
              >
              Add Payment Account
            </Button>
          </Space>
        </ContainerFilter>

        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
          }
        />

        <Pagination
          current={pagination.page}
          total={pagination.totalData}
          defaultPageSize={pagination.perPage}
          pageSize={pagination.perPage}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onShowSizeChange={(_current, size) => {
            changeLimit(size);
          }}
          showSizeChanger={true}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          onChange={changePage}
        />
      </div>

      <Modal
        title="Update Status Confirmation"
        open={isModalUpdateStatusVisible}
        onOk={handleStatusChange}
        onCancel={() => {
          setIsModalUpdateStatusVisible(false);
          setTmpData(initialPaymentConfig);
        }}
        okText="Yes"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to change <b>"{tmpData.label}"</b> status to{' '}
          <b>"{!tmpData.isActive ? 'Active' : 'Inactive'}"</b>?
        </p>
      </Modal>
      
      <Modal
        title="Delete Confirmation"
        open={isModalDeleteVisible}
        onOk={handleDelete}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setTmpData(initialPaymentConfig);
        }}
        okText="Yes"
        confirmLoading={isLoadingAction}
        okButtonProps={{ type: 'primary' }}
      >
        <p>
          Are you sure want to delete <b>"{tmpData.label}"</b>?
        </p>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentConfigs;

import React from 'react';
import {
  AntDesignOutlined
} from '@ant-design/icons';
import {
  message,
  Select,
  Typography,
  Button,
} from 'antd';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import { BaseResponseProps } from '../../types/config.type';
import {
  AppConfigProps,
  initAppConfig,
  PaymentConfigProps,
} from '../../types/paymentConfig.type';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { theme } from "../../assets/theme";
import { ContainerFilter } from '../product';
import {
  iconFileRemove as IconFileRemove
} from "../../assets/icons/screens";

interface ResponseProps extends BaseResponseProps<PaymentConfigProps> {}

const { Text, Title } = Typography;
const { Option } = Select;

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ],
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
];

const TermsConditions = () => {

  const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
  const [tnc, setTnc] = React.useState<AppConfigProps>(initAppConfig);
  const [newTnc, setNewTnc] = React.useState<AppConfigProps>(initAppConfig);
  // const [userIds, setUserIds] = React.useState<string>();
  // const [userIdsArr, setUserIdsArr] = React.useState<any[]>([]);

  

  const handleInitTnc = async () => {
    const raw = await httpRequest.get('/app-configs/TERMS_AND_CONDITIONS');
    setTnc(raw.data.payload);
    setNewTnc(raw.data.payload);
    setIsDataLoaded(true);
  };

  React.useEffect(() => {
    handleInitTnc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAppConfig = async () => {
    try {
      setIsLoadingAction(true);
      const raw = await httpRequest.patch('/app-configs/TERMS_AND_CONDITIONS', {
        value: newTnc.value,
      });
      setTnc(newTnc);
      console.log(raw);
      message.success('Data updated');
    } catch (err: any) {
      message.error(err.message);
    } finally {
      setIsLoadingAction(false);
    }
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon={<AntDesignOutlined />}
        title="Terms & Conditions"
        rightAction={<></>}
      />
      <div style={{ padding: '0px 24px'}}>
        {/* <button onClick={() => console.log(data)}>check Data</button> */}

        <ContainerFilter
          style={{
            justifyContent: 'space-between',
          }}
        >
          <div 
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <IconFileRemove/>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                color: 'black',
                display: 'block'
              }}
            >
              Terms & Conditions
            </Text>
            <div 
              style={{ 
                width: '1px',
                height: '25px',
                backgroundColor: theme.colors.ash500,
                margin: '0 5px'
              }}
            />
            <Text
              style={{
                fontSize: 12,
                fontWeight: '400',
                color: '#768499',
                display: 'block'
              }}
            >
              Excepteur sint occaecat cupidatat non proident
            </Text>
          </div>
          <Button type="primary" onClick={() => handleChangeAppConfig()} loading={isLoadingAction}>
            Save
          </Button>
        </ContainerFilter>
        
        { isDataLoaded && (
          <ReactQuill
          style={{ padding: "15px 20px" }}
          theme="snow"
          defaultValue={tnc.value}
          onChange={(val) =>
            setNewTnc({
              ...newTnc,
              value: val,
            })
          }
          modules={quillModules}
          formats={quillFormats}
        />
        ) }
          
      </div>
    </React.Fragment>
  );
};

export default TermsConditions;
import { ProductProps } from './products.type';

export enum ETransactionStatus {
	created = 'created', // menunggu pembayaran
	payment_failed = 'payment_failed', // pembayaran bermasalah
	paid = 'paid', // dibayar
	process = 'process', // diproses

	canceled = 'canceled', // dibatalkan
	rejected = 'rejected', // ditolak
	refunded = 'refunded', // refunded
	expired = 'expired', // expired

	sent = 'sent', // dikirim
	received = 'received', //diterima
}

export interface TransactionItemProperties {
	transactionItemId: string;
	transactionId: string;
	productId: string;
	metaProduct: ProductProps;
	qty: number;
	unitOfMeasure: string;
	pricePerUnit: number;
	totalPrice: number;
	createdAt?: Date;
	updatedAt?: Date;
	transaction?: TransactionProps;
}

export interface TransactionProps {
	transactionId: string;
	transactionCode: string;
	totalFinalPrice: number;
	transactionStatus: ETransactionStatus;
	metaBuyer: any;
	createdAt?: Date;
	updatedAt?: Date;
	transactionItems?: TransactionItemProperties[];
}

export const initialTransactions: TransactionProps = {
	transactionId: '',
	transactionCode: '',
	totalFinalPrice: 0,
	transactionStatus: ETransactionStatus.created,
	metaBuyer: {},
};

import { message } from 'antd';
import axios from 'axios';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { APIResponse } from '../types/base.type';
import { DeleteProductFilePayload, GenerateProductFileUploadURLPayload, GetProductFileURLPayload } from '../types/productFile.type';
import { ProductProps } from '../types/products.type';

export async function generateProductFileUploadURL(
  productId: string,
  contentType: string
): Promise<GenerateProductFileUploadURLPayload> {
  const res: APIResponse<GenerateProductFileUploadURLPayload> = await httpRequest.post('/products/' + productId + '/upload-url', {
    contentType,
  });

  return res.data.payload;
}
message.config({
  maxCount: 1
})

export async function getProductFile(
  productId: string
): Promise<GetProductFileURLPayload> {
  const res: APIResponse<GetProductFileURLPayload> = await httpRequest.get(
    '/products/' + productId + '/content'
  );

  return res.data.payload;
}

export async function deleteProductFile(
  productId: string
): Promise<DeleteProductFilePayload> {
  const res: APIResponse<DeleteProductFilePayload> = await httpRequest.delete(
    '/products/' + productId + '/content'
  );

  return res.data.payload;
}

export async function updateProductFile(
  productId: string,
  data: {productContentURL: string}
): Promise<ProductProps> {
  const res: APIResponse<ProductProps> = await httpRequest.patch(
    '/products/' + productId,
    data
  );

  return res.data.payload;
}

export async function processUploadProductFile(productId: string, file: any, setIsLoading: (val: boolean) => void) {
  setIsLoading(true);

  // Source to show image
  const src = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
  });

  console.log('file', file);
  console.log('src', src);

  let resUploadURL;
  try {
    resUploadURL = await generateProductFileUploadURL(
      productId,
      file.type
    );

    console.log('resUploadURL', resUploadURL);
  } catch (err) {
    message.error('Failed to generate upload url: ' + getErrorMessage(err));

    setIsLoading(false);
  }

  if (!resUploadURL) {
    return;
  }

  try {
    await axios.put(resUploadURL.uploadUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    await updateProductFile(productId, { productContentURL: resUploadURL.filePath });

    message.success('Uploaded successfully');
    setIsLoading(false);
  } catch (err) {
    setIsLoading(false);
    message.error('Failed to upload: ' + getErrorMessage(err));
  }
}